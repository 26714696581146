import { HttpClient } from "@angular/common/http";
import {
  AfterContentChecked,
  Component,
  DoCheck,
  IterableDiffers,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  Router,
  Event,
  NavigationEnd,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { environment } from "../environments/environment";
import { currencies } from "currencies.json";
import { Observable, of } from "rxjs";
import { BnNgIdleService } from "bn-ng-idle";
import { ValidationMessage } from "./constants/validationMessage";
import { OrderBookService } from "./pages/advanced-exchange/orderbook.service";
import { NotificationDropdownComponent } from "./shared/notification-dropdown/notification-dropdown.component";

declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterContentChecked {
  @ViewChild(NotificationDropdownComponent, {static : false}) notificationDropdownComponent: NotificationDropdownComponent;
  title = "cryptocurrency";
  currUrl: string;
  isLoggedIn: boolean = false;
  selected: any = "/signup";
  selectedemailv: boolean = false;
  userImage: any = "assets/img/dummy-user.png";
  userName: any;
  profileData: any = {};
  chatArr: any = [];
  modeType: any = "night";
  language: any = "Hindi";
  totalNotification: any = 0;
  iterableDiffer;
  totalMessage: any = 0;
  products: any = [];
  currencyListArray: any = [];
  userPreferredCurrency: any;
  allValidationMessage: any;
  logo = "assets/svg/newlogo1.svg";
  walletImage: string;
  notificationImage: string;
  constructor(
    public routes: Router,
    public service: ServiceService,
    private iterableDiffers: IterableDiffers,
    private httpClient: HttpClient,
    private bnIdle: BnNgIdleService,
    public order_service: OrderBookService,
    public http: HttpClient
  ) {
    this.allValidationMessage = ValidationMessage;
    this.getCurrencyPrice();
    if (localStorage.getItem("credential")) {
      this.bnIdle.startWatching(10800).subscribe((res) => {
        if (res) {
          this.service.logoutFunc();
          this.service.showErrorMessage(
            "Your session has expired please login again."
          );
        }
      });
    }

    localStorage.setItem("cooki", "cooki");
    var self = this;
    $(document).bind("mousedown", function (e) {
      if (e.button == 0) {
        self.checkSession();
      }
    });
    /*********** Routing Managed Start Here ***************/
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split("/")[1];

        if (localStorage.getItem("credential")) {
          this.service.changeLoginSub("auth/signIn");
          if (this.currUrl == "auth") {
            routes.navigate([""]);
          }
        } else {
          this.service.changeLoginSub("logout");
        }
      }
    });
    /*********** Routing Managed End Here ***************/
    // to show notification count
    this.service.getMessage().subscribe((res) => {
      if (res.text.message) {
        if (res.text.message.includes("trade request from")) {
          this.chatArr.push(res.text);
        }
      }
    });
    this.iterableDiffer = iterableDiffers.find([]).create(null); // for unseen notification count
    
  }
  ngOnInit() {
    this.service.selectedCurrencySub.subscribe((res) => {
      this.userPreferredCurrency = res;
    });
    this.service.isProfileImageUpdateSubject.subscribe((isUpdate) => {
      if (isUpdate) {
        this.getProfile();
      } else {
        this.getProfile();
      }
    });
    // if (localStorage.getItem('theme')) {
    //   this.defaultTheme();
    // }
    // else {
    //   localStorage.setItem('theme', 'night')
    // }
    window.scrollTo(0, 0);
    this.service.initSocket();
    this.service.initSocketChat(); //chat
    this.service.initSocketNotification(); // notification
    this.checkSession();
    this.service.loginObs.subscribe((response) => {
      if ($(".mobileresposnsive").hasClass("menushowhide")) {
        $(".mobileresposnsive").removeClass("menushowhide");
      }
      $("main").click(function () {
        if ($(".mobileresposnsive").hasClass("menushowhide")) {
          $(".mobileresposnsive").removeClass("menushowhide");
        }
      });
      $("li.custom-space").click(function () {
        if ($(".sidenav").hasClass("onetwo")) {
          $(".sidenav").removeClass("onetwo");
        }
      });
      $("main").click(function () {
        $(".sidenav").addClass("onetwo");
      });
      if (response == "auth/signIn") {
        this.isLoggedIn = true;
        this.getProfile();
      } else {
        this.isLoggedIn = false;
      }
    });
    this.httpClient.get("assets/language.json").subscribe((data) => {
      this.products = data;
    });
    // this.currencyList();
    this.httpClient.get("assets/curenncy.json").subscribe((res) => {
      this.currencyListArray = res;
    });

    this.defaultTheme();
    let a = localStorage.getItem("theme");

    this.modeChangeFunc(localStorage.getItem("theme") || "night");
  }
  defaultTheme() {
    // this.modeType = localStorage.getItem('theme')
    // if (this.modeType == 'night') {
    $("body").attr("data-theme-version", "dark-s");
    // } else {
    //   $("body").attr("data-theme-version", "light-s")
    // }
  }
  modeChangeFunc(type) {
    localStorage.setItem("theme", type);
    this.modeType = type;
    // type == "night"
    if (type == "night") {
      $("body").attr("data-theme-version", "dark-s");
      // this.service.themeColour.next('night')
      $("body").css({
        background: "#18191d",
      });
      this.logo = "assets/svg/newlogo1.svg"
      this.walletImage = "assets/images/wallet-04.svg"
      this.notificationImage = "assets/images/noticefication.svg"
    } else {
      $("body").attr("data-theme-version", "light-s");
      $("body").css({
        background: "#fff",
      });
      this.logo = "assets/svg/newlogo1-dark.svg"
      this.walletImage = "assets/images/wallet-04-dark.svg"
      this.notificationImage = "assets/images/noticefication-dark.svg"

    }
    this.service.themeColour.next(this.modeType);
  }
  /** Function to get diffrence between timestamp */
  diff_minutes(dt2, dt1) {
    var diff = (dt2 - dt1) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }
  /** Function for check session */
  checkSession() {
    if (
      localStorage.getItem("token") != null &&
      localStorage.getItem("session") != null
    ) {
      let minDiff = this.diff_minutes(
        parseInt(localStorage.getItem("session")),
        new Date().getTime()
      );
      localStorage.setItem("session", new Date().getTime().toString());
      if (minDiff >= 10) {
        this.logoutFunc();
      }
    } else {
      localStorage.setItem("session", new Date().getTime().toString());
    }
  }

  logout() {
    $("#logout").modal({ backdrop: "static" });
  }

  cancelConfirm(): void {
    $("#logout").modal("hide");
    $('#clearNotification').modal("hide");
  }

  logoutFunc() {
    $("#logout").modal("hide");
    localStorage.removeItem("credential");
    localStorage.removeItem("authToken");
    localStorage.removeItem("profiledata");
    this.routes.navigate(["/auth/signIn"]);
  }
  // <script>
  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  // </script>
  selectTab(tab) {
    this.selected = tab;
  }
  navigateToBy(path) {
    this.routes.navigateByUrl(path);
  }
  showNav = false;
  openNavMobile() {}
  navigateTo(path) {
    const token = localStorage.getItem("credential");
    if (token != null) {
      this.isLoggedIn = true;
      this.routes.navigate(["/basic-buy-sell/sell"]);
    } else {
      this.isLoggedIn = false;
      this.routes.navigate(["/auth/signIn"]);
    }
    //this.routes.navigateByUrl(path)
  }
  // Get Profile Name-Email
  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.showSpinner();
      this.service.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            var profileData = res["data"];
            this.profileData = res["data"];
            localStorage.setItem(
              "profiledata",
              JSON.stringify(this.profileData)
            );
            this.userImage = profileData.imageUrl
              ? profileData.imageUrl
              : "assets/svg/profile.png";
            this.userName = profileData.firstName;
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          // this.service.showError(err['message']);
        }
      );
    }
  }
  security(event) {
    if (event == "wallet") {
      this.routes.navigate(["/my-wallet"]);
    } else if (event == "withdraw") {
      this.routes.navigate(["/withdraw-history"]);
    } else if (event == "deposit") {
      this.routes.navigate(["/deposit-history"]);
    }
  }
  new(event) {
    if (event == "profile") {
      this.routes.navigate(["/my-profile"]);
    } else if (event == "login") {
      this.routes.navigate(["/login-history"]);
    } else if (event == "logout") {
      $("#logout").modal({ backdrop: "static" });
    }
  }
  // dropdown
  drop() {
    $(".dropdown-toggle").dropdown();
  }
  // get notification
  notifications() {
    this.routes.navigate(["/notifications"]);
  }
  ngAfterContentChecked(): void {
    let changes = this.iterableDiffer.diff(this.service.notificationArr);
    let notiArray = JSON.parse(localStorage.getItem("noti"));
    let mesiArray = JSON.parse(localStorage.getItem("mesi"));
    if (notiArray) {
      let unSeenNotifCount = notiArray.filter((x) => x.isSeen == false);
      this.totalNotification = unSeenNotifCount.length;
    }
    if (mesiArray) {
      let unSeenMessageCount = mesiArray.filter((x) => x.isSeen == false);
      this.totalMessage = unSeenMessageCount.length;
    }
  }
  currencyList() {
    this.currencyListArray = currencies;
  }

  // change this with dynamic array
  selectCurrencyModalOpen() {
    $("#selectCurrencyModalId").modal("show");
  }

  selectCurrencyModalClose() {
    $("#selectCurrencyModalId").modal("hide");
  }

  currencySelected(event, img, rate) {
    this.userPreferredCurrency = event;
    localStorage.setItem("userPreferredCurrency", this.userPreferredCurrency);
    localStorage.setItem("userPreferredCurrencyImage", img);
    localStorage.setItem("userPreferredCurrencyPrice", rate);
    this.service.selectedCurrencySub.next(this.userPreferredCurrency);
    this.service.selectedCurrencySubImage.next(img);
    this.service.selectedCurrencySubPrice.next(rate);
    $("#selectCurrencyModalId").modal("hide");
  }

  getCurrencyPrice() {
    let currencyName = this.currencyListArrayNew.map((x) => x.pair).join(",");
    let url = `${
      "https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=" +
      currencyName
    }`;
    this.httpClient.get(url).subscribe((res) => {
      localStorage.setItem("currencyPrice", JSON.stringify(res));
    });
  }

  currencyListArrayNew = [
    {
      pair: "AED",
      rate: 3.67,
      symbol: "د.إ",
      fullName: "UAE dirham",
      imageUrl: "https://public.bnbstatic.com/image/currencies/AED.png",
    },
    {
      pair: "ARS",
      rate: 312.96,
      symbol: "ARS$",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/ARS.png",
    },
    {
      pair: "AUD",
      rate: 1.47,
      symbol: "A$",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/AUD.png",
    },
    {
      pair: "BDT",
      rate: 102.35,
      symbol: "৳",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/BDT.png",
    },
    {
      pair: "BGN",
      rate: 1.86,
      symbol: "лв",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/BGN.png",
    },
    {
      pair: "BHD",
      rate: 0.376,
      symbol: ".د.ب",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/BHD.png",
    },
    {
      pair: "BOB",
      rate: 6.9,
      symbol: "$b",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/BOB.png",
    },
    {
      pair: "BRL",
      rate: 5.22,
      symbol: "R$",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/BRL.png",
    },
    {
      pair: "CAD",
      rate: 1.34,
      symbol: "C$",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/CAD.png",
    },
    {
      pair: "CHF",
      rate: 0.9366,
      symbol: "CHF",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/CHF.png",
    },
    {
      pair: "CNY",
      rate: 6.96,
      symbol: "¥",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/CNY.png",
    },
    {
      pair: "COP",
      rate: 4774.47,
      symbol: "COL$",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/COP.png",
    },
    {
      pair: "CZK",
      rate: 23.13,
      symbol: "Kč",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/CZK.png",
    },
    {
      pair: "DKK",
      rate: 7.06,
      symbol: "KR",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/DKK.png",
    },
    {
      pair: "EGP",
      rate: 24.61,
      symbol: "£",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/EGP.png",
    },
    {
      pair: "EUR",
      rate: 0.9499,
      symbol: "€",
      fullName: "Euro",
      imageUrl: "https://public.bnbstatic.com/image/currencies/EUR.png",
    },
    {
      pair: "GBP",
      rate: 0.8161,
      symbol: "£",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/GBP.png",
    },
    {
      pair: "HKD",
      rate: 7.77,
      symbol: "HKD",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/HKD.png",
    },
    {
      pair: "HRK",
      rate: 7.17,
      symbol: "kn",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/HRK.png",
    },
    {
      pair: "HUF",
      rate: 390.09,
      symbol: "Ft",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/HUF.png",
    },
    {
      pair: "IDR",
      rate: 15433.16,
      symbol: "Rp",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/IDR.png",
    },
    {
      pair: "INR",
      rate: 81.84,
      symbol: "₹",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/INR.png",
    },
    {
      pair: "JPY",
      rate: 135.39,
      symbol: "¥",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/JPY.png",
    },
    {
      pair: "KES",
      rate: 122.69,
      symbol: "KSh",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/KES.png",
    },
    {
      pair: "KWD",
      rate: 0.3069,
      symbol: "ب.د",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/KWD.png",
    },
    {
      pair: "KZT",
      rate: 469.73,
      symbol: "₸",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/KZT.png",
    },
    {
      pair: "MAD",
      rate: 10.56,
      symbol: "م.د.",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/MAD.png",
    },
    {
      pair: "MNT",
      rate: 3425.86,
      symbol: "₮",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/MNT.png",
    },
    {
      pair: "MXN",
      rate: 19.54,
      symbol: "Mex$",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/MXN.png",
    },
    {
      pair: "NGN",
      rate: 462.64,
      symbol: "₦",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/NGN.png",
    },
    {
      pair: "NZD",
      rate: 1.56,
      symbol: "NZ$",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/NZD.png",
    },
    {
      pair: "OMR",
      rate: 0.3845,
      symbol: "ر.ع.",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/OMR.png",
    },
    {
      pair: "PEN",
      rate: 3.84,
      symbol: "S/.",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/PEN.png",
    },
    {
      pair: "PHP",
      rate: 56.01,
      symbol: "₱",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/PHP.png",
    },
    {
      pair: "PKR",
      rate: 224.45,
      symbol: "₨",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/PKR.png",
    },
    {
      pair: "PLN",
      rate: 4.46,
      symbol: "zł",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/PLN.png",
    },
    {
      pair: "QAR",
      rate: 3.64,
      symbol: "ر.ق",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/QAR.png",
    },
    {
      pair: "RON",
      rate: 4.67,
      symbol: "lei",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/RON.png",
    },
    {
      pair: "RUB",
      rate: 62.19,
      symbol: "₽",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/RUB.png",
    },
    {
      pair: "SAR",
      rate: 3.75,
      symbol: "ر.س",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/SAR.png",
    },
    {
      pair: "SEK",
      rate: 10.34,
      symbol: "kr",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/SEK.png",
    },
    {
      pair: "THB",
      rate: 34.75,
      symbol: "฿",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/THB.png",
    },
    {
      pair: "TRY",
      rate: 18.64,
      symbol: "₺",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/TRY.png",
    },
    {
      pair: "TWD",
      rate: 30.52,
      symbol: "NT$",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/TWD.png",
    },
    {
      pair: "UAH",
      rate: 36.93,
      symbol: "₴",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/UAH.png",
    },
    {
      pair: "UGX",
      rate: 3727.45,
      symbol: "USh",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/UGX.png",
    },
    {
      pair: "USD",
      rate: 1.05,
      symbol: "$",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/USD.png",
    },
    {
      pair: "VES",
      rate: 11.23,
      symbol: "Bs",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/VES.png",
    },
    {
      pair: "VND",
      rate: 23892.75,
      symbol: "₫",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/VND.png",
    },
    {
      pair: "ZAR",
      rate: 17.23,
      symbol: "R",
      fullName: null,
      imageUrl: "https://public.bnbstatic.com/image/currencies/ZAR.png",
    },
  ];
}
