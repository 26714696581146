import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ValidationMessage } from 'src/app/constants/validationMessage';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.css']
})
export class NotificationDropdownComponent implements OnInit, OnChanges {
  @Input() currUrl : any
  @Input() image : any
  notificationData: any = []
  foo
  maxNotificationsToShow = 5;
  message: any;
  title: any;
  allValidationMessage: any;
  constructor(private router: Router, public service: ServiceService, public appC: AppComponent) { }
  ngOnInit() {
    window.scrollTo(0, 0)
    this.service.notification.subscribe((res) => {
    })
    // this.readNotification()
    console.log(this.currUrl);
    this.allValidationMessage = ValidationMessage;
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currUrl']) {
      this.getNotification() 
    }
  }
  navigateTo(order) {
    let arr = order.data
    this.appC.chatArr = []
    if ((order.message.includes('You have a Trade Request'))) {
      let exchangeDetails = arr
      let navigationExtras: NavigationExtras = { state: { exchangeDetails: exchangeDetails } };
      this.router.navigate(['contactExchange'], navigationExtras)
    }
     if(order.message.includes('Your nominee has been')){
      this.router.navigate(['/nominee'])
    }
    else if(order.message.includes('Your account has been Resumed' || 'Your account has been suspened')){
      this.router.navigate(['/p2p-data'])
    }
    else if(order.message.includes('Your KYC has been')){
      this.router.navigate(['/user-kyc'])
    }
    else if(order.message.includes('Your KYC submission has been')){
      this.router.navigate(['/kyclist'])
    }
    else if(order.message.includes('You have successfully placed' || 'Buy order has Completed for' || 'Sell order has Completed for')){
      this.router.navigate(['/buy-crypto'])
    }
    else if(order.message.includes('Your transaction has been successfully')){
      this.router.navigate(['/my-wallet'])
    }
    else if(order.message.includes('amount have been internally transfered ' || 'mount have been internally received')){
      this.router.navigate(['/internal-transfer-history'])
    }
    else if(order.message.includes('Your deposit request for')){
      this.router.navigate(['/deposit-history'])
    }
    else if(order.message.includes('Your withdraw request for ')){
      this.router.navigate(['/withdraw-history'])
    }
  }
  // readNotification() {
  //   this.service.getCandyPixelForms('notification/read-notification').subscribe((res) => {
  //     this.getNotification()
  //   })
  // }
  isLoading:boolean = false 
  notFoundData:boolean =false
  unreadcount : number = 0
  getNotification() {
    this.isLoading =true
    this.notFoundData =false
    this.service.showSpinner()
    this.service.getCandyPixelForms('notification/get-notification-data?role=USER').subscribe((res) => {
      this.isLoading =false
      if (res['status'] == 1618) {
        this.service.hideSpinner()
        this.service.notificationArr = res['data']
        if(res['data'].length>0){
          this.notFoundData =false

          let unreadNotification = res['data'].filter((ele)=>{
            return ele.isSeen == false
          })
          this.unreadcount = unreadNotification.length


        }else{
          this.notFoundData =true

        }
        localStorage.setItem('noti',JSON.stringify(res['data']))
      }
      else{
        this.isLoading =false
        this.notFoundData =true
        this.service.notificationArr = []

      }
    },err=>{
      this.isLoading =false
      this.notFoundData =true
    })
  }
  notId : any
  clearNot(id?){
    this.notId = id
    this.title = id ? 'Clear Notification' : 'Clear All Notification'
    this.message = id ? this.allValidationMessage.CONFIRM_DELETE_NOTIFICATION : this.allValidationMessage.CONFIRM_DELETE_NOTIFICATION_ALL
    $('#clearNotification').modal({ backdrop: "static" });
  }
  loading = false
  deleteNotifications() {
    var apireq = {};
    this.service.showSpinner();
    this.loading = true
    this.service.deleteApi("notification/delete-notification").subscribe(
      (res) => {
        $('#clearNotification').modal("hide");
        this.service.hideSpinner();
        this.loading =false
        // this.readNotification();

        this.getNotification();
        this.service.showSuccessMessage("Notification Delete Successfully!");
      },
      (err) => {
        this.loading =false
        this.service.hideSpinner();
      }
    );
    
  }
  
  deleteNotificationsById(id?) {

      if(!id){
        this.deleteNotifications()
        return
      }

    var apireq = {};
    this.service.showSpinner();
    this.service.deleteApi(`notification/clear-notification?id=${id}`).subscribe(
      (res) => {
        $('#clearNotification').modal("hide");
        this.service.hideSpinner();
        // this.readNotification();
        this.getNotification();
        this.service.showSuccessMessage("Notification Delete Successfully!");
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  extractEmail(message: string): string {
    // Regular expression to match an email address
    const emailRegex = /[\w\.-]+@[\w\.-]+\.\w+/;

    // Use the match() method to find the email address in the message
    const emailMatch = message.match(emailRegex);

    // Check if an email address was found and return it, or return an empty string
    return emailMatch ? emailMatch[0] : '';
  }
  loadMore() {
    this.maxNotificationsToShow += 5; // Increase the number of notifications to show (e.g., add 5 more)
  }



  extractFirstLetter(message: string): string {
    return message.charAt(0); // Get the first character of the message
  }
   timeAgo(timestamp) {
    const now : any = new Date();
    const past : any = new Date(timestamp);
    const seconds = Math.floor((now - past) / 1000);
  
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const strTime = `${hours}:${minutes}:${seconds} ${ampm}`;
      return `${year}-${month}-${day} ${strTime}`;
    };
  
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return formatDate(past);
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 2) {
      return formatDate(past);
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return `${interval} days ago`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return `${interval} hours ago`;
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return `${interval} minutes ago`;
    }
    return "just now";
  }
  
 
  
}
