import { Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { ServiceService } from "src/app/service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { LabelType, Options } from "@angular-slider/ngx-slider";
import * as CanvasJS from "../../../assets/canvasjs.min";
import { element } from 'protractor';
import { OrderBook } from 'src/app/app-order';
import { OrderBookService } from './orderbook.service';
import { OrderbookbinanceService } from './orderbookbinance.service';
import { TradeHistoryService } from './tradeHistorsy.service';
import { BinanceLiveprice } from './binnace-price.service';
declare var $: any;
declare const Datafeeds: any;
declare const TradingView: any;
declare const AmCharts: any;
declare var CanvasJS: any;

@Component({
  selector: "app-advanced-exchange",
  templateUrl: "./advanced-exchange.component.html",
  styleUrls: ["./advanced-exchange.component.css"],
})
export class AdvancedExchangeComponent implements OnInit {
  orderPriceDecimalLabel = 0.01
  text = "text-style";
  binaceLastLivePrice: any
  showRefresh = false
 
  // charts:any
  value: number = 0;
  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + "<b>%</b>";
        case LabelType.High:
          return value + "<b>%</b>";
        default:
          return value + "%";
      }
    },
    showTicks: true,
    tickStep: 20
  };
  // options: Options = {

  //   floor : 0,
  //   ceil : 100,
  //   showTicks : true,
  //   tickStep:10
  // };
  values: number = 0;
  // optionsNext: Options = {
  //   showTicksValues: true,
  //   stepsArray: [
  //     { value: 0 },
  //     { value: 20 },
  //     { value: 40 },
  //     { value: 60 },
  //     { value: 80 },
  //     { value: 100 }
  //   ]
  // };
  optionsNext: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + "<b>%</b>";
        case LabelType.High:
          return value + "<b>%</b>";
        default:
          return value + "%";
      }
    },
    showTicks: true,
    tickStep: 20
  };
  coinList: any = [];
  regexForEightChar = /^(\d+)?([*]?\d{0,8})?$/;
  drop: string = "close";
  coinpair: any = [];
  // tab: any = "market";
  tab: any = "limit";
  buyPercentage: number = 0;
  sellPercentage: number = 0;
  buyForm: FormGroup;
  sellForm: FormGroup;
  editLimitForm: FormGroup //edit form value 
  orderTab: string = "open";
  coinListBalance: any = []
  // activeButton : string = 'open'
  activeButton: boolean = true;
  isBuyDataTrue: boolean = false;
  isSellDataTrue: boolean = false;
  showDetailFun: any = "PAIRSL";

  activeCoin: String = ""
  coinDetail: any = {};
  openOrderList: any = [];
  openOrderPageNumber: number = 1;
  orderHistoryList: any = [];
  orderHistoryPageNumber: number = 1;
  orderId: any;
  baseCoinBalance: number;
  tradeHistoryList: any = [];
  execCoinBalance: any;
  stopLimit: any;
  buyOrderList = [];
  sellOrderList: any = [];
  private chart: AmChart;
  status: boolean;
  connection: any;
  sellQuantity: Number = 0;
  execCoin: any = "BTC";
  baseCoin: any = "USD";
  loginStatus: any = false;
  cancelOrderTrue: any = false;
  cancelAllOrderTrue: any = false;
  myInterval: any;
  renderer: any;
  subject: any;
  modeType: any = 'day';
  // modeType: any = 'night';
  marketPrice: any = 0;
  marketalldata: any;
  mysktInterval: any;
  arr: any[];
  selectedInterval: any = "1 day";
  showData: any = false;
  marketBuyForm: FormGroup;
  marketSellForm: FormGroup;
  limitSellForm: FormGroup;
  limitBuyForm: FormGroup;
  myTradeHistoryList: any = [];
  marketDataArray: any = [];
  errMsg: string;
  showGraph: any = "Professional";
  showDetail: any = "MARKETTRADE";
  // tab5: any = true;
  // tab1: any = false;
  tab5: any = false;
  tab1: any = true;
  // activeButton: string = 'open'
  basecoins = "BTC";
  baseCoinTrade: any
  execCoinTrade: any
  marketButton: boolean = true;
  clearField: {
    marketBuyClear: boolean;
    limitBuyClear: boolean;
    BuyClear: boolean;
    marketSellClear: boolean;
    limitSellClear: boolean;
    SellClear: boolean;
  };
  fee: any = {
    TakerFee: 0,
    MakerFee: 0,
  };
  totalOrderBookPrice: Number = 0
  orderVal: any = "both";
  updateNewPrice: any;
  // order_service: OrderBookService;

  length(n) {
    return new Array(n);
  }

  buyOrSellType = 'BUY';
  refresh: boolean = true;

  public order_service_binance
  public trade_service_binance
  public binnace_livePrice_data
  constructor(
    public service: ServiceService,
    public activated: ActivatedRoute,
    public route: Router,
    public order_service: OrderBookService,
    private AmCharts: AmChartsService,
    private injector: Injector

  ) {


    window.scrollTo(0, 0);
    this.anotherConst()



  }
  ngOnInit() {
    this.formValidationFunctionality();
    this.editFormValidations()
    // this.service.cmcData.subscribe((res) => {
    // if (res) {
    //   this.cmcData = res
    if (localStorage.getItem("credential")) {
      this.loginStatus = true;
    }
    // this.getGraphData()
    this.getAllCoinPairFunc();
    this.getCoinListFunc(this.baseCoin, this.execCoin);
    // this.getPrice();
    this.getAllCoinPairFuncs();
    this.getBuyOrSellType('BUY')
    // slider
    this.getfavuriteList()
    let coinPair = localStorage.getItem('coin_pair')
    let base = coinPair.split('|')[0] || 'USDT'
    let exec = coinPair.split('|')[1] || 'BTC'
    this.getSelectedPair(base, exec)
    this.getMinimuvalue()
    // }
    // })
    this.service.selectedCurrencySub.subscribe(res => {
      this.selectedCurrency = res
    })

    $(document).on('click.bs.dropdown.data-api', '.keep_it_open', function (e) {
      e.stopPropagation();
    });
    // if (localStorage.getItem('statusEnableDisable') == 'ENABLE') {
    // this.liquiditys()
    // }

  }
  async anotherConst() {
    let a = await order.getOrderBookStatus()
    if (order.orderStatus == 'ENABLE') {
      this.order_service_binance = this.injector.get(OrderbookbinanceService)
      this.trade_service_binance = this.injector.get(TradeHistoryService)
      setTimeout(() => {
        this.initializebinanceSoket()
      }, 3000);
      this.order_service_binance.constructor2()
      // this.binnace_livePrice_data = this.injector.get(BinanceLiveprice)
      // this.initalizelocalSocket()
      // this.binnace_livePrice_data.constructor3()
    }
    else {
      this.binnace_livePrice_data = this.injector.get(BinanceLiveprice)
      this.initalizelocalSocket()
      this.binnace_livePrice_data.constructor3()
    }
    this.service.themeColour.subscribe(res => {
      console.log(this.modeType);
      
      this.modeType = res;
      // if (this.modeType == 'day') {
      //   setTimeout(() => {
      //     // $("body").attr("data-theme-version", "light")
      //   }, 500);
      // } else {
      //   setTimeout(() => {
      //     // $("body").attr("data-theme-version", "dark")
      //   }, 500);
      // }
      if (!this.refresh) {
        this.drawTradingChart(this.coinDetail, "D");
      }
    })

    /** Listener for web socket connection */

    /** Listener for web socket connection */
    this.activated.queryParams.subscribe((res) => {
      this.baseCoinTrade = res.baseCoin
      this.execCoinTrade = res.execCoin
    })

    this.binnace_livePrice_data.binnaceLivePrice.subscribe((res) => {
      if(res){
        this.binaceLastLivePrice = res.p
        this.updateNewPrice = res.p
      }
    })
    setTimeout(() => {
      this.isUpdateItem()
    }, 4000);
  }
  totoalValue: any
  lastexecutedprice_buy : any
  lastexecutedprice_sell : any
  initalizelocalSocket() {

    this.order_service.initSocket()
    var self = this;
    this.order_service.connection.subscribe((connection) => {
      this.connection = connection;
      if (this.connection == "online") {
        if (Object.keys(this.coinDetail).length != 0) this.manageSubscribeAndUnsubscribeFunc(1);
      }
    });
    this.order_service.wsExchange.addEventListener("message", function (event) {
      if (!event.data.includes("symbol not supported")) {
        let data = JSON.parse(event.data);
        switch (data.messageType) {
          case "TICKER_UPDATE":
            // self.marketPrice = data.data.lastPrice ? data.data.lastPrice : 0;
            self.marketPrice = data.data ? data.data.lastPrice : 0;
            self.marketalldata = data.data;
            if (self.tab == "market") {
              self.buyForm.patchValue({
                price: self.marketPrice,
              });
              self.sellForm.patchValue({
                price: self.marketPrice,
              });
            }
            break;
          case "ORDER_BOOK_UPDATE":
            let arr = []
            let arr1 = []
            if (data.data.BUY) {
              // let current = Number(data.data.BUY[0].price).toFixed(2)
              //  let range = Number(self.orderPriceDecimalLabel)

              data.data.BUY.forEach((obj) => {
                // if (obj.side == "BUY") {
                // let price = Number(Number(current) - Number(range)).toFixed(2)
                // if (Number(self.orderPriceDecimalLabel) < 0.05) {
                //   price = Number(Number(current) - Number(range)).toFixed(2)
                // }
                // else if (Number(self.orderPriceDecimalLabel) < 0.5) {
                //   price = Number(Number(current) - Number(range)).toFixed(1)
                // }
                // else {
                //   price = Number(Number(current) - Number(range)).toFixed(0)
                // }
                let objs = {
                  "price": obj.price,
                  "amount": obj.amount,
                  "side": obj.side,
                  "volume": (obj.volume / obj.quantity) * 100
                }
                arr.push(objs)
                // range = Number(self.orderPriceDecimalLabel) + Number(range)
                self.buyOrderList = arr
              },
              )
              self.lastexecutedprice_buy = data.data.BUY[0]
            }
            if (data.data.SELL) {
              // let current = Number(data.data.SELL[0].price).toFixed(2)
              // let range = Number(self.orderPriceDecimalLabel)
              console.log(data.data.SELL);

              data.data.SELL.forEach((obj) => {
                // let price = Number(Number(current) - Number(range)).toFixed(2)
                // if (Number(self.orderPriceDecimalLabel) < 0.05) {
                //   price = Number(Number(current) - Number(range)).toFixed(2)
                // }
                // else if (Number(self.orderPriceDecimalLabel) < 0.5) {
                //   price = Number(Number(current) - Number(range)).toFixed(1)
                // }
                // else {
                //   price = Number(Number(current) - Number(range)).toFixed(0)
                // }
                console.log(obj);

                let objs = {
                  "price": obj.price,
                  "amount": obj.amount,
                  "side": obj.side,
                  "volume": (obj.volume / obj.quantity) * 100
                }
                arr1.push(objs)
                //  range = Number(self.orderPriceDecimalLabel) + Number(range)
                self.sellOrderList = arr1
              }
              )
              self.lastexecutedprice_sell = data.data.SELL[0]
            }

            break;
          case "TRADE_HISTORY_UPDATE":
            // data.data.forEach((obj) => {

            //   self.tradeHistoryList = [...obj]
            //   // self.tradeHistoryList.push({
            //   //   price: self.validatePointFunc(obj.price),
            //   //   amount: self.validatePointFunc(obj.amount),
            //   //   total: self.validatePointFunc(obj.price * obj.amount),
            //   //   time: obj.time,
            //   //   side: obj.side,
            //   // });
            // });
            self.tradeHistoryList = [...data.data]
            self.tradeHistoryList = self.sortData1(self.tradeHistoryList);
            break;
        }
        self.orderBookPrice(self.orderPriceDecimalLabel) // set the decimal place 
      }
    });


  }
  isUpdateItem() {
    this.limitBuyForm.patchValue({
      limitBuyPrice: this.toFixed(this.updateNewPrice, 2),
    });
  }

  initializebinanceSoket() {
    this.order_service_binance.data_binnace.subscribe((_value) => {


      if (_value) {
        let arr = []
        let arr1 = []
        for (let i = 0; i < 15; i++) {
          this.binaceLastLivePrice =  _value && _value[i] && _value[i].price;
          this.updateNewPrice =  _value && _value[i] && _value[i].price;
          if (_value && _value[i] && _value[i].side === true) {
            let objs = {
              "price": _value && _value[i] && _value[i].price,
              "amount": _value && _value[i] && _value[i].amount,
              "side": _value && _value[i] && _value[i].side ? _value && _value[i].side : 'BUY'
            }
            arr.push(objs)
            this.buyOrderList = arr
          } else if (_value && _value[i] &&  _value[i].side === false) {
            let objs = {
              "price": _value && _value[i] && _value[i].price,
              "amount": _value && _value[i] && _value[i].amount,
              "side": _value && _value[i] && _value[i].side ? _value && _value[i].side : 'SELL'
            }
            arr1.push(objs)
            this.sellOrderList = arr1
          }
        }
      }


    })
    this.order_service_binance.data_binnace_ticker.subscribe((res)=>{
      this.marketalldata = res
      })
    setTimeout(() => {
      this.isUpdateItem()
    }, 4000);
    setTimeout(() => {
      this.trade_service_binance.trade_data.subscribe((res) => {
        this.tradeHistoryList = res
      })
    }, 10000);

  }

  validatePointFunc(amt) {
    // return Number(amt).toFixed(8);
    return Number(amt).toFixed(5);
  }
  cmcData: any
  selectedCurrency: any
 



  volume() {
    this.tab5 = !this.tab5;
    this.tab1 = !this.tab1
  }

  patchOrderBookPrice(price) {
    this.limitBuyForm.patchValue({
      limitBuyPrice: price
    })
    this.buyForm.patchValue({
      price: price
    })
    this.limitSellForm.patchValue({
      limitSellPrice: price
    })
    this.sellForm.patchValue({
      price: price
    })
    // this.marketBuyForm.patchValue({
    //   marketBuyPrice: price
    // })
  }
  patchOrderBookPriceSell(price) {
    this.limitSellForm.patchValue({
      limitSellPrice: price
    })
    this.sellForm.patchValue({
      price: price
    })
    this.limitBuyForm.patchValue({
      limitBuyPrice: price
    })
    this.buyForm.patchValue({
      price: price
    })
    // this.marketSellForm.patchValue({
    //   marketSellPrice: price
    // })
  }

  change() {
    this.tab1 = !this.tab1;
    this.tab5 = !this.tab5
  }
  bankDetails(showSectionFun) {
    this.showDetailFun = showSectionFun;
  }
  switchGraph(showGraph) {
    this.showGraph = showGraph;
  }
  /** Function to manage exponential data */
  manageExponential(num) {
    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[\+\-]*\d+/i.test(num)) {
      var zero = "0",
        parts = String(num).toLowerCase().split("e"), //split into coeff and exponent
        es = Number(parts.pop()), //store the exponential part
        l = Math.abs(es), //get the number of zeros
        sign = es / l,
        coeff_array = parts[0].split(".");
      if (sign === -1) {
        num = zero + "." + new Array(l).join(zero) + coeff_array.join("");
      } else {
        var dec = coeff_array[1];
        if (dec) l = l - dec.length;
        num = coeff_array.join("") + new Array(l + 1).join(zero);
      }
      return num;
    } else {
      return num;
    }
  }

  editFormValidations() {
    this.editLimitForm = new FormGroup({
      editLimitprice: new FormControl("", [
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      editLimitAmount: new FormControl("", [
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ])
    })
  }

  // Form Validation Functionality
  formValidationFunctionality() {
    this.marketBuyForm = new FormGroup({
      marketBuyPrice: new FormControl("", 
      [
        // Validators.required,
        // Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      marketBuyquantity: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      marketBuyTotal: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
    });


    this.marketSellForm = new FormGroup({
      marketSellPrice: new FormControl("", [
        // Validators.required,
        // Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      marketSellquantity: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      marketSellTotal: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
    });


    this.limitBuyForm = new FormGroup({
      limitBuyPrice: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      limitBuyQuantity: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      limitBuyTotal: new FormControl(
        "",[ Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/)]
      ),
    });


    this.limitSellForm = new FormGroup({
      limitSellPrice: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      limitSellQuantity: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      limitSellTotal: new FormControl(
        "",
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/)
      ),
    });


    this.buyForm = new FormGroup({
      stopLimit: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      price: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      quantity: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      total: new FormControl("", Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/)),
    });


    this.sellForm = new FormGroup({
      stopLimit: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      price: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      quantity: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/),
      ]),
      total: new FormControl("", Validators.pattern(/^(\d+)?([.]?\d{0,25})?$/)),
    });
  }

  // Tab Navigation Functionality
  tabNav(val) {
    this.resetForm();
    this.tab = val;
    this.showData = false;
    this.buyPercentage = 0;
    this.sellPercentage = 0;
    this.getCoinListFunc(this.coinDetail.baseCoin, this.coinDetail.execCoin); // changes for limit price
  }
  // Calculate Buy Amount Functioanlity
  selectBuyPerFunc(action, percent, type, orderType) {
    switch (orderType) {
      case "MARKETBUY":
        if (action == "percentage") {
          this.buyPercentage = Number(percent);
          if (type == "buy") {
            this.marketBuyForm.patchValue({

              marketBuyTotal:  (
                  (Number(this.baseCoinBalance) * (Number(percent) / 100)) 
                ).toFixed(8)
               
            });
          }
          console.log("><><><><><><><><><><><",this.baseCoinBalance);
          
          this.getPriceFunc(this.lastexecutedprice_buy.price, orderType); // Function call here so that total value updated as other values change
        }
        break;
      case "LIMITBUY":
        if (Number(this.limitBuyForm.value.limitBuyPrice) <= 0) {
          this.service.showErrorMessage("Please enter the valid price");
          return;
        } else {
          if (action == "percentage") {
            this.buyPercentage = Number(percent);
            if (type == "buy") {
              this.limitBuyForm.patchValue({
                limitBuyQuantity: (
                  (Number(this.baseCoinBalance) * (Number(percent) / 100)) /
                  this.limitBuyForm.value.limitBuyPrice
                ).toFixed(8),
              });
            }
            this.getPriceFunc(this.limitBuyForm.value.limitBuyPrice, orderType); // Function call here so that total value updated as other values change
          }
        }
        break;
      case "STOPLIMITBUY":
        if (Number(this.buyForm.value.price) <= 0) {
          this.service.showErrorMessage("Please enter the valid price");
          return;
        } else {
          if (action == "percentage") {
            this.buyPercentage = Number(percent);
            if (type == "buy") {
              this.buyForm.patchValue({
                quantity: (
                  (Number(this.baseCoinBalance) * (Number(percent) / 100)) /
                  this.buyForm.value.price
                ).toFixed(8),
              });
            }
            this.getPriceFunc(this.buyForm.value.price, orderType); // Function call here so that total value updated as other values change
          }
        }
        break;
    }
  }

  //calculate sell amount functionality
  selectSellPerFunc(action, percent, type, orderType) {
    switch (orderType) {
      case "MARKETSELL":
        if (action == "percentage") {
          this.sellPercentage = Number(percent);
          if (type == "sell") {
            this.marketSellForm.patchValue({
              marketSellquantity:
                Number(this.execCoinBalance) * (Number(percent) / 100),
            });
          }
          this.getPriceFunc(this.lastexecutedprice_sell.price, orderType); // Function call here so that total value updated as other values change
        }
        break;
      case "LIMITSELL":
        if (action == "percentage") {
          this.sellPercentage = Number(percent);
          if (type == "sell") {
            this.limitSellForm.patchValue({
              limitSellQuantity:
                Number(this.execCoinBalance) * (Number(percent) / 100),
            });
          }
          this.getPriceFunc(this.limitSellForm.value.limitSellPrice, orderType); // Function call here so that total value updated as other values change
        }
        break;
      case "STOPLIMITSELL":
        if (action == "percentage") {
          this.sellPercentage = Number(percent);
          if (type == "sell") {
            this.sellForm.patchValue({
              quantity: Number(this.execCoinBalance) * (Number(percent) / 100),
            });
          }
          this.getPriceFunc(this.sellForm.value.price, orderType); // Function call here so that total value updated as other values change
        }
        break;
    }
  }
  showTradeStatus(status) {
    this.orderTab = status;
    switch (status) {
      case "marketTrade":
        // case 'open':
        this.marketButton = status;
        break;
      case "myTrade":
        this.marketButton = status;
    }
  }

  tradeDataDetails(showSection) {
    this.showDetail = showSection;
  }
  // Order Status Functionality
  showOrderStatus(status) {
    this.orderTab = status;
    switch (status) {
      case "open":
        this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
        this.activeButton = status;
        break;
      case "history":
        this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        this.activeButton = status;
        break;
      case "trade":
        this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        this.activeButton = status;
        break;
      case "fund":
        this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        this.activeButton = status;
        break;
    }
  }
  coinListData: any = []

  // Get Coin List Functionality
  getCoinListFunc(baseCoin, execCoin) {
    var coinList = [];
    this.service.showSpinner();
    this.service
      .getCandyPixelForms("wallet/wallet/get-all-user-balance-and-coinlist")
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            coinList = res["data"];
            this.coinListData = res['data']
            var coinDetail = coinList.filter(
              (x) => x.coinShortName == String(baseCoin).toUpperCase()
            );
            var coinDetail_exec = coinList.filter(
              (x) => x.coinShortName == String(execCoin).toUpperCase()
            );
            if (this.buyOrSellType == 'BUY') {
              this.limitBuyForm.patchValue({
                limitBuyPrice: this.toFixed(this.binaceLastLivePrice, 2),
              });
              this.buyForm.patchValue({
                price: this.toFixed(this.binaceLastLivePrice, 2),
              });
            } else {
              this.limitSellForm.patchValue({
                limitSellPrice: this.toFixed(this.binaceLastLivePrice, 2),
              });
              this.sellForm.patchValue({
                price: this.toFixed(this.binaceLastLivePrice, 2),
              });
            }
            this.getTakerMakerFee();
            if (this.tab == "market") {
              this.buyForm.patchValue({
                price: this.marketPrice,
              });
              this.sellForm.patchValue({
                price: this.marketPrice,
              });
            }
            this.showRefresh= false
          } else {
            this.showRefresh= true
          }
        },
        (err) => {
          this.showRefresh= true
          this.service.hideSpinner();
        }
      );
  }
  actualcoinpair: any;
  // Get All Coin Pair Functionality
  getAllCoinPairFunc() {
    if (this.service.walletData.length > 0) {
      this.coinpair = this.service.walletData;
      var coinDetail = {
        baseCoin: this.coinpair[0].baseCoin.toLowerCase(),
        base: this.coinpair[0].baseCoin,
        exec: this.coinpair[0].execCoin[0],
        execCoin: this.coinpair[0].execCoin[0].toLowerCase(),
        coinPair:
          this.coinpair[0].execCoin[0].toLowerCase() +
          "_" +
          this.coinpair[0].baseCoin.toLowerCase(),
        CoinPair:
          this.coinpair[0].execCoin[0] + "/" + this.coinpair[0].baseCoin,
        socketPair:
          this.coinpair[0].execCoin[0] + "_" + this.coinpair[0].baseCoin,
      };
      this.coinDetail = localStorage.getItem("coinDetail")
        ? JSON.parse(localStorage.getItem("coinDetail"))
        : coinDetail;
      this.baseCoin = this.coinDetail.base;
      this.execCoin = this.coinDetail.exec;

      setTimeout(() => {
        this.manageSubscribeAndUnsubscribeFunc(1);
      }, 1000);
      this.showallcoindata();
      this.drawTradingChart(this.coinDetail, "D");
      this.drawDepthChart();
      if (localStorage.getItem("credential")) {
        this.getbaseCoinBalance(this.coinDetail.baseCoin, "base");
        this.getbaseCoinBalance(this.coinDetail.execCoin, "exec");
        this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
        this.getCoinListFunc(this.coinpair[0].baseCoin, this.coinpair[0].execCoin);
      }
    } else {
      this.service.showSpinner();
      // this.service.getCandyPixelForms('wallet/coin/get-symbol-list').subscribe(res => {
      this.service
        .getCandyPixelForms("wallet/coin/get-symbol-list")
        .subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.coinpair = res["data"];
              var coinDetail = {
                baseCoin: this.coinpair[0].baseCoin.toLowerCase(),
                base: this.coinpair[0].baseCoin,
                exec: this.coinpair[0].execCoin[0],
                execCoin: this.coinpair[0].execCoin[0].toLowerCase(),
                coinPair:
                  this.coinpair[0].execCoin[0].toLowerCase() +
                  "_" +
                  this.coinpair[0].baseCoin.toLowerCase(),
                CoinPair:
                  this.coinpair[0].execCoin[0] +
                  "/" +
                  this.coinpair[0].baseCoin,
                socketPair:
                  this.coinpair[0].execCoin[0] +
                  "_" +
                  this.coinpair[0].baseCoin,
              };

              this.coinDetail = localStorage.getItem("coinDetail")
                ? JSON.parse(localStorage.getItem("coinDetail"))
                : coinDetail;
              this.baseCoin = this.coinDetail.base;
              this.execCoin = this.coinDetail.exec;
              //this.actualcoinpair = this.coinDetail.execCoin

              setTimeout(() => {
                this.manageSubscribeAndUnsubscribeFunc(1);

              }, 1000);
              this.showallcoindata();
              this.drawTradingChart(this.coinDetail, "D");
              this.drawDepthChart();
              if (localStorage.getItem("credential")) {
                this.getbaseCoinBalance(this.coinDetail.baseCoin, "base");
                this.getbaseCoinBalance(this.coinDetail.execCoin, "exec");
                this.getOrderHistoryOfParticularCoinPair(
                  this.coinDetail.coinPair
                );
                // this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
                this.getCoinListFunc(this.coinpair[0].baseCoin, this.coinpair[0].execCoin);
              }
            }
            this.showRefresh = false
          },
          (err) => {
            this.service.hideSpinner();
            this.showRefresh = true
          }
        );
    }
  }
  mergeCoinPair() {


    for (let item of this.coinpair) {
      let filterArray = []
      filterArray = this.coinListData.filter((res) => {
        return res.coinShortName == item.baseCoin
      })
      item['changePrice'] = filterArray[0].price_usd

    }
  }

  getSelectedPair(baseCoin, execCoin) {

    localStorage.setItem('coin_pair', baseCoin +'|'+ execCoin)

    // this.order_service_binance.connect('wss://testnet.binance.vision/ws-api/v3')

    // this.tabNav("market");
    this.tabNav("limit");
    this.manageSubscribeAndUnsubscribeFunc(2);
    this.getTakerMakerFee();
    this.marketalldata = {};
    this.baseCoin = baseCoin;
    this.execCoin = execCoin;
    this.drop = "hide";
    this.coinDetail = {
      baseCoin: baseCoin.toLowerCase(),
      base: baseCoin,
      execCoin: execCoin.toLowerCase(),
      exec: execCoin,
      coinPair: execCoin.toLowerCase() + "_" + baseCoin.toLowerCase(),
      CoinPair: execCoin + "/" + baseCoin,
      socketPair: execCoin + "_" + baseCoin,
    };
    localStorage.setItem("coinDetail", JSON.stringify(this.coinDetail));
    this.drawTradingChart(this.coinDetail, "D");
    this.drawDepthChart();
    this.getMinimuvalue()
    this.coinpair.map((e) => {
      if (e.marketValue.length > 0) {
        e.marketValue = [];
      }
    });
    this.showallcoindata();
    this.buyOrderList = [];
    this.sellOrderList = [];
    this.tradeHistoryList = [];
    this.manageSubscribeAndUnsubscribeFunc(1);
    if (localStorage.getItem("credential")) {
      this.getbaseCoinBalance(this.coinDetail.baseCoin, "base");
      this.getbaseCoinBalance(this.coinDetail.execCoin, "exec");
      this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
      this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
      this.getCoinListFunc(baseCoin, execCoin);
    }
    if(this.binnace_livePrice_data){
      this.binnace_livePrice_data.desot()
      this.binnace_livePrice_data.constructor3()
    }

  }

  // Get Price For Trade
  getPriceFunc(price, type) {
    if (type == "STOPLIMITBUY") {
      if (this.buyForm.value.quantity) {
        this.buyForm.patchValue({
          total: (
            Number(this.buyForm.value.price) * Number(this.buyForm.value.quantity)
          ).toFixed(8),
        });
      }
    } else if (type == "LIMITBUY") {
      if (this.limitBuyForm.value.limitBuyQuantity) {
        this.limitBuyForm.patchValue({
          limitBuyTotal: (
            Number(this.limitBuyForm.value.limitBuyPrice) *
            Number(this.limitBuyForm.value.limitBuyQuantity)
          ).toFixed(8),

        });
      }
    } else if (type == "LIMITSELL") {
      if (this.limitSellForm.value.limitSellQuantity) {
        this.limitSellForm.patchValue({
          limitSellTotal: (
            Number(this.limitSellForm.value.limitSellPrice) *
            Number(this.limitSellForm.value.limitSellQuantity)
          ).toFixed(8),
        });
      }
    } else if (type == "STOPLIMITSELL") {
      if (this.sellForm.value.quantity) {
        this.sellForm.patchValue({
          total: (
            Number(this.sellForm.value.price) *
            Number(this.sellForm.value.quantity)
          ).toFixed(8),
        });
      }
    } else if (type == "MARKETBUY") {
      this.marketBuyForm.patchValue({
        marketBuyquantity: (
          Number(this.marketBuyForm.value.marketBuyTotal) /
          Number(price)
        ).toFixed(8),
      });
      console.log(">>>>>>>>>>>>>>>>>",price);
      
    } else if (type == "MARKETSELL") {
      this.marketSellForm.patchValue({
        marketSellTotal: (
          Number(price) *
          Number(this.marketSellForm.value.marketSellquantity)
        ).toFixed(8),
      });
    }
  }

  // Get Order History of Particular Coin Pair Functionality
  getOrderHistoryOfParticularCoinPair(coinPair) {
    this.orderHistoryList = [];
    this.service.showSpinner();
    this.service
      .getCandyPixelForms(
        "order-service-" + coinPair + "/my-order-history?symbol=" + coinPair
      )
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            res["data"].forEach((obj) => {
              if (
                !(obj.orderType == "MARKET" && obj.orderStatus == "CREATED")
              ) {
                this.orderHistoryList.push({
                  orderTime: new Date(obj.creationTime).getTime(),
                  pair: obj.instrument.replace("_", "/"),
                  orderType: obj.orderType,
                  orderSide: obj.orderSide,
                  blockedBalance: obj.blockedBalance,
                  totalQuantity: obj.totalQuantity,
                  makerFee: obj.makerFee,
                  takerFee: obj.takerFee,
                  actualAmountExecutedMaker: obj.totalQuantity - (obj.totalQuantity * (obj.makerFee / 100)),
                  actualAmountExecutedTaker: obj.totalQuantity - (obj.totalQuantity * (obj.takerFee / 100)),
                  price: obj.avgExecutionPrice
                    ? obj.avgExecutionPrice
                    : obj.limitPrice,
                  amount:
                    obj.currentQuantity == 0
                      ? obj.quantity
                      : obj.currentQuantity,
                  orderStatus: obj.orderStatus,
                  happening:
                    obj.orderStatus == "CANCELLED"
                      ? (obj.currentQuantity / obj.quantity) * 100
                      : ((obj.quantity - obj.currentQuantity) / obj.quantity) *
                      100,
                  total: obj.avgExecutionPrice
                    ? Number(obj.avgExecutionPrice) *
                    (obj.currentQuantity == 0
                      ? Number(obj.quantity)
                      : Number(obj.currentQuantity))
                    : (obj.currentQuantity == 0
                      ? Number(obj.quantity)
                      : Number(obj.currentQuantity)) * Number(obj.limitPrice),
                });
                if (obj.stopPrice == "0") {
                  obj.tri_cond = ">";
                } else if (obj.stopPrice == "1") {
                  obj.tri_cond = "<";
                } else if (obj.stopPrice == "2") {
                  obj.tri_cond = "----";
                } else {
                  obj.tri_cond = "";
                }
              }
            });
            this.orderHistoryList = this.sortData(this.orderHistoryList);
            this.connection = "online";
          } else {
            this.connection = "offline";
          }
        },
        (err) => {
          this.connection = "offline";
          this.service.hideSpinner();
        }
      );
  }

  /** Function for sort data */
  sortData(arr) {
    arr.sort((a, b) => b.orderTime - a.orderTime);
    return arr;
  }

  /** FUNCTION TO GET TAKER MAKER FEE */
  getTakerMakerFee() {
    this.service
      .getCandyPixelForms(
        "wallet/get-taker-maker-fee?coinName=" + this.baseCoin
      )
      .subscribe((response: any) => {
        // this.service.getCandyPixelForms('wallet/get-taker-maker-fee?coinName=' + this.execCoin).subscribe((response: any) => {

        if (response.status == 200) {
          this.fee = response.data;
          this.fee.TakerFee = response.data.TakerFee;
          this.fee.MakerFee = response.data.MakerFee;
        }
      });
  }

  // Get Open Order List of Particular Coin Pair Functionality
  getOpenOrderOfParticularCoinPair(coinPair) {
    this.openOrderList = [];
    this.service.showSpinner();
    this.service
      .getCandyPixelForms("order-service-" + coinPair + "/my-active-orders?symbol=" + coinPair)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            res["data"].forEach((obj) => {
              if (
                !(obj.orderType == "MARKET" && obj.orderStatus == "CREATED")
              ) {
                this.openOrderList.push({
                  orderId: obj.orderId,
                  orderTime: new Date(obj.creationTime).getTime(),
                  pair: obj.instrument.replace("_", "/"),
                  orderType: obj.orderType,
                  orderSide: obj.orderSide,
                  orderStatus: obj.orderStatus,
                  blockedBalance: obj.blockedBalance,
                  totalQuantity: obj.totalQuantity,
                  makerFee: obj.makerFee,
                  takerFee: obj.takerFee,
                  actualAmountExecutedMaker: obj.totalQuantity - (obj.totalQuantity * (obj.makerFee / 100)),
                  actualAmountExecutedTaker: obj.totalQuantity - (obj.totalQuantity * (obj.takerFee / 100)),
                  price: obj.avgExecutionPrice
                    ? obj.avgExecutionPrice
                    : obj.limitPrice,
                  amount:
                    obj.orderStatus == "PARTIALLY_EXECUTED"
                      ? obj.currentQuantity
                      : obj.quantity,
                  happening:
                    obj.currentQuantity == obj.quantity
                      ? "0"
                      : (obj.currentQuantity / obj.quantity) * 100,
                  total: obj.avgExecutionPrice
                    ? obj.avgExecutionPrice *
                    (obj.orderStatus == "PARTIALLY_EXECUTED"
                      ? obj.currentQuantity
                      : obj.quantity)
                    : (obj.orderStatus == "PARTIALLY_EXECUTED"
                      ? obj.currentQuantity
                      : obj.quantity) * obj.limitPrice,
                  click: false,
                });
                if (obj.stopPrice == "0") {
                  obj.tri_cond = ">";
                } else if (obj.stopPrice == "1") {
                  obj.tri_cond = "<";
                } else if (obj.stopPrice == "2") {
                  obj.tri_cond = "----";
                } else {
                  obj.tri_cond = "";
                }
              }
            });
            this.openOrderList = this.sortData(this.openOrderList);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }

  // Get Amount For Trade
  getAmountFunc(amt, type) {
    if (!amt) {
      this.limitBuyForm.patchValue({
        limitBuyTotal: ''
      })
      this.buyForm.patchValue({
        total: ''
      })
      this.limitSellForm.patchValue({
        limitSellTotal: ''
      })
      this.sellForm.patchValue({
        total: ''
      })
      this.marketBuyForm.patchValue({
        marketBuyTotal: ''
      })
      this.marketSellForm.patchValue({
        marketSellTotal: ''
      })
      return
    }
    if (type == "STOPLIMITBUY") {
      this.buyForm.patchValue({
        total: (
          Number(this.buyForm.value.price) * Number(this.buyForm.value.quantity)
        ).toFixed(8),
      });
    } else if (type == "LIMITBUY") {
      this.limitBuyForm.patchValue({
        limitBuyTotal: (
          Number(this.limitBuyForm.value.limitBuyPrice) *
          Number(this.limitBuyForm.value.limitBuyQuantity)
        ).toFixed(8),
      });
    } else if (type == "LIMITSELL") {
      this.limitSellForm.patchValue({
        limitSellTotal: (
          Number(this.limitSellForm.value.limitSellPrice) *
          Number(this.limitSellForm.value.limitSellQuantity)
        ).toFixed(8),
      });
    } else if (type == "STOPLIMITSELL") {
      this.sellForm.patchValue({
        total: (
          Number(this.sellForm.value.price) *
          Number(this.sellForm.value.quantity)
        ).toFixed(8),
      });
    }
    else if (type == "MARKETBUY") {
      this.marketBuyForm.patchValue({
        marketBuyTotal: (
          Number(this.marketBuyForm.value.marketBuyquantity) *
          Number(this.binaceLastLivePrice)
        ).toFixed(8),
      });
    }
    else if (type == "MARKETSELL") {
      this.marketSellForm.patchValue({
        marketSellTotal: (
          Number(this.marketSellForm.value.marketSellquantity) *
          Number(this.binaceLastLivePrice)
        ).toFixed(8),
      });
    }
  }
  totalAmoutCalculate(amt, type) {
    if (!amt) {
      this.limitBuyForm.patchValue({
        limitBuyQuantity: ''
      })
      this.buyForm.patchValue({
        quantity: ''
      })
      this.limitSellForm.patchValue({
        limitSellQuantity: ''
      })
      this.sellForm.patchValue({
        quantity: ''
      })
      this.marketBuyForm.patchValue({
        marketBuyquantity: ''
      })
      this.marketSellForm.patchValue({
        marketSellquantity: ''
      })
      return
    }
    if (type == "STOPLIMITBUY") {
      this.buyForm.patchValue({
        quantity: (
          Number(this.buyForm.value.total) / Number(this.buyForm.value.price)
        ).toFixed(8),
      });
    } else if (type == "LIMITBUY") {
      this.limitBuyForm.patchValue({
        limitBuyQuantity: (
          Number(this.limitBuyForm.value.limitBuyTotal) /
          Number(this.limitBuyForm.value.limitBuyPrice)
        ).toFixed(8),
      });
    } else if (type == "LIMITSELL") {
      this.limitSellForm.patchValue({
        limitSellQuantity: (
          Number(this.limitSellForm.value.limitSellTotal) /
          Number(this.limitSellForm.value.limitSellPrice)
        ).toFixed(8),
      });
    } else if (type == "STOPLIMITSELL") {
      this.sellForm.patchValue({
        quantity: (
          Number(this.sellForm.value.total) / Number(this.sellForm.value.price)
        ).toFixed(8),
      });
    }
    else if (type == "MARKETBUY") {
      this.marketBuyForm.patchValue({
        marketBuyquantity: (
          Number(this.marketBuyForm.value.marketBuyTotal) /
          Number(this.binaceLastLivePrice)
        ).toFixed(8),
      });
    }
    else if (type == "MARKETSELL") {
      this.marketSellForm.patchValue({
        marketSellquantity: (
          Number(this.marketSellForm.value.marketSellTotal) /
          Number(this.binaceLastLivePrice)
        ).toFixed(8),
      });
    }
  }

  // Place Buy / Sell Order Functionality
  placeOrderFunc(action) {
    var apiReq;
    switch (action) {
      case "MARKETBUY":
        if (this.marketBuyForm.invalid) {
          this.clearField.marketBuyClear = true;
          return;
        }
        apiReq = {
          orderSide: "BUY",
          orderType: "MARKET",
          quantity: Number(this.marketBuyForm.value.marketBuyquantity),
          symbol: this.coinDetail.coinPair,
          makerFee: this.fee.TakerFee,
          takerFee: this.fee.MakerFee,
          totalQuantity: Number(this.marketBuyForm.value.marketBuyTotal),
        };
        // }
        break;
      case "MARKETSELL":
        if (this.marketSellForm.invalid) {
          this.clearField.marketSellClear = true;
          return;
        }
        apiReq = {
          orderSide: "SELL",
          orderType: "MARKET",
          quantity: Number(this.marketSellForm.value.marketSellquantity),
          symbol: this.coinDetail.coinPair,
          makerFee: this.fee.TakerFee,
          takerFee: this.fee.MakerFee,
          totalQuantity: Number(this.marketSellForm.value.marketSellTotal),
        };
        // }
        break;
      case "STOPLIMITBUY":
        if (this.buyForm.invalid) {
          return;
        }
        apiReq = {
          limitPrice: Number(this.buyForm.value.price),
          orderSide: "BUY",
          orderType: "STOP_LIMIT",
          quantity: Number(this.buyForm.value.quantity),
          stopPrice: Number(this.buyForm.value.stopLimit),
          symbol: this.coinDetail.coinPair,
          totalQuantity: Number(this.buyForm.value.total),
          makerFee: this.fee.TakerFee,
          takerFee: this.fee.MakerFee
        };
        break;
      case "LIMITBUY":
        if (this.limitBuyForm.invalid) {
          return;
        }
        apiReq = {
          limitPrice: Number(this.limitBuyForm.value.limitBuyPrice),
          orderSide: "BUY",
          orderType: "LIMIT",
          quantity: Number(this.limitBuyForm.value.limitBuyQuantity),
          symbol: this.coinDetail.coinPair,
          totalQuantity: Number(this.limitBuyForm.value.limitBuyTotal),
          makerFee: this.fee.TakerFee,
          takerFee: this.fee.MakerFee
        };
        break;
      case "LIMITSELL":
        if (this.limitSellForm.invalid) {
          return;
        }
        apiReq = {
          limitPrice: Number(this.limitSellForm.value.limitSellPrice),
          orderSide: "SELL",
          orderType: "LIMIT",
          quantity: Number(this.limitSellForm.value.limitSellQuantity),
          symbol: this.coinDetail.coinPair,
          totalQuantity: Number(this.limitSellForm.value.limitSellTotal),
          makerFee: this.fee.TakerFee,
          takerFee: this.fee.MakerFee
        };
        break;
      case "STOPLIMITSELL":
        if (this.sellForm.invalid) {
          return;
        }
        apiReq = {
          limitPrice: Number(this.sellForm.value.price),
          orderSide: "SELL",
          orderType: "STOP_LIMIT",
          quantity: Number(this.sellForm.value.quantity),
          stopPrice: Number(this.sellForm.value.stopLimit),
          symbol: this.coinDetail.coinPair,
          totalQuantity: Number(this.sellForm.value.total),
          makerFee: this.fee.TakerFee,
          takerFee: this.fee.MakerFee
        };
        break;
    }
    if (action == "MARKETBUY") {
      if (this.manageExponential(this.baseCoinBalance) < this.limitBuyForm.value.limitBuyTotal) {
        this.service.showErrorMessage(
          "You don't have enough balance in your account"
        );
        return;
      }
    } else if (action == "MARKETSELL") {
      if (apiReq.quantity > this.execCoinBalance) {
        this.service.showErrorMessage(
          "You don't have enough balance in your account"
        );
        return;
      }
    }
    // else {
    //   var fee: any = 0;
    //   if (apiReq.orderType == "MARKET") {
    //     fee = this.fee.TakerFee * this.baseCoinBalance;
    //   } else {
    //     if (apiReq.orderSide == "BUY") {
    //       fee = this.fee.MakerFee * this.baseCoinBalance;
    //       if (
    //         apiReq.quantity * apiReq.limitPrice + fee >
    //         this.baseCoinBalance
    //       ) {
    //         this.service.showErrorMessage(
    //           "You don't have enough balance in your account"
    //         );
    //         return;
    //       }
    //     } else {
    //       fee = this.fee.TakerFee * this.execCoinBalance;
    //       if (apiReq.quantity + fee > this.execCoinBalance) {
    //         this.service.showErrorMessage(
    //           "You don't have enough balance in your account"
    //         );
    //         return;
    //       }
    //     }
    //   }
    // }
    if (this.coinDetail.base == "USDT") {
    }
    if (
      (apiReq.orderType == "STOP_LIMIT" || apiReq.orderType == "LIMIT") &&
      apiReq.limitPrice <= 0
    ) {
      this.service.showErrorMessage("Please enter the valid amount");
      return;
    } else if (apiReq.quantity <= 0) {
      this.service.showErrorMessage("Please enter the valid quantity");
      return;
    } else {
      this.service.showSpinner();
      let data = {
        messageType: "",
        params: {
          symbol: "",
        },
      };
      data.messageType = "UNSUBSCRIBE_ORDER_BOOK";
      data.params.symbol = this.coinDetail.socketPair;
      if (localStorage.getItem('statusEnableDisable') == 'DISABLE') {
        this.order_service.wsExchange.send(JSON.stringify(data));
      }
      this.isBuyDataTrue = true
      this.isSellDataTrue = true
      this.service
        .postCandyPixelForms(
          "order-service-" + this.coinDetail.coinPair + "/place-order",
          apiReq
        )
        .subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200 || res["status"] == 201 || res["status"] == 202) {
              this.isBuyDataTrue = false
              this.isSellDataTrue = false
              this.service.showSuccessMessage(res["message"]);
              this.getbaseCoinBalance(this.coinDetail.baseCoin, "base");
              this.getbaseCoinBalance(this.coinDetail.execCoin, "exec");
              this.getOrderHistoryOfParticularCoinPair(
                this.coinDetail.coinPair
              );
              this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
              // this.buyOrderList = [];
              // this.sellOrderList = [];
              data.messageType = "SUBSCRIBE_ORDER_BOOK";
              data.params.symbol = this.coinDetail.socketPair;
              this.order_service.wsExchange.send(JSON.stringify(data));
              this.drawDepthChart();
             
            } else {
              this.service.showErrorMessage(res["message"]);
              this.isBuyDataTrue = false
              this.isSellDataTrue = false
            }
          },
          (err) => {
            this.isBuyDataTrue = false
            this.isSellDataTrue = false
            this.service.hideSpinner();
            if (err["status"] == "401") {
              localStorage.clear();
              this.route.navigate(["/login"]);
              this.service.showErrorMessage("Session Expired!");
            } else {
              this.service.showErrorMessage("Please wait for few minutes & reload the site.");
            }
          }
        );
    }
  }

  resetForm() {
    this.marketBuyForm.reset();
    this.marketSellForm.reset();
    this.limitBuyForm.reset();
    this.limitSellForm.reset();
    this.buyForm.reset();
    this.sellForm.reset();
  }

  // Manage Socket Subscribe And Unsubscribe Functionality
  manageSubscribeAndUnsubscribeFunc(mode) {
    if (order.orderStatus == 'ENABLE') {
      return
    }
    let data = {
      messageType: "",
      params: {
        symbol: "",
      },
    };
    switch (mode) {
      case 1:
        /** Code for subscribe ticker data */
        if (this.order_service.wsExchange && this.order_service.wsExchange.readyState) {
          data.messageType = "SUBSCRIBE_TICKER";
          data.params.symbol = this.coinDetail.socketPair;
          this.order_service.wsExchange.send(JSON.stringify(data));
          /** Code for subscribe order book data */
          data.messageType = "SUBSCRIBE_ORDER_BOOK";
          data.params.symbol = this.coinDetail.socketPair;
          this.order_service.wsExchange.send(JSON.stringify(data));

          /** Code for subscribe trade history data */
          data.messageType = "SUBSCRIBE_TRADE_HISTORY";
          data.params.symbol = this.coinDetail.socketPair;
          this.order_service.wsExchange.send(JSON.stringify(data));
          return;
        } else {
          this.myInterval = setInterval((x) => {
            this.manageSubscribeAndUnsubscribeFunc(1);
          }, 10000);
        }

        break;
      case 2:
        /** Code for unsubscribe ticker data */


        if (this.order_service.wsExchange && this.order_service.wsExchange.readyState) {
          data.messageType = "UNSUBSCRIBE_TICKER";
          data.params.symbol = this.coinDetail.socketPair;
          this.order_service.wsExchange.send(JSON.stringify(data));

          /** Code for unsubscribe order book data */
          data.messageType = "UNSUBSCRIBE_ORDER_BOOK";
          data.params.symbol = this.coinDetail.socketPair;
          this.order_service.wsExchange.send(JSON.stringify(data));

          /** Code for unsubscribe trade history data */
          data.messageType = "UNSUBSCRIBE_TRADE_HISTORY";
          data.params.symbol = this.coinDetail.socketPair;
          this.order_service.wsExchange.send(JSON.stringify(data));
          break;
        }
        else {
          this.myInterval = setInterval((x) => {
            this.manageSubscribeAndUnsubscribeFunc(2);
          }, 10000);
        }
    }
  }

  // Delete Open Order Functionality
  // openCancel(orderId) {
  //   this.orderId = orderId;
  //   $("#myModal").modal("show");
  // }
  openAllCancel() {
    $("#myModal").modal("show");
  }

  openConfiramtionModal() {
    $('#editFormValueModal').modal('show')
  }

  openeditConfiramtionModal() {
    $('#confirmation').modal('show')
    $('#editFormValueModal').modal('hide')
  }
  deleteOpenOrder(orderID) {
    var apiReq = {
      orderId: orderID,
      symbol: this.coinDetail.coinPair,
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms("order-service-" + this.coinDetail.coinPair + "/cancel-order", apiReq).subscribe(
      (res) => {
        $("#myModal").modal("hide");
        if (res["status"] == 200) {
          this.service.hideSpinner();
          this.service.showSuccessMessage(res["message"]);
          this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
          this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
          this.getbaseCoinBalance(this.coinDetail.baseCoin, "base");
          this.getbaseCoinBalance(this.coinDetail.execCoin, "exec");
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        // $("#myModal").modal("hide");
      }
    );
  }
  deleteAllOpenOrder() {
    this.cancelAllOrderTrue = true
    let url = `order-service-btc_usdt/cancel-all-order?symbol=${(this.coinDetail.coinPair).toUpperCase()}`
    this.service.showSpinner();
    this.service.delete(url)
      .subscribe(
        (res) => {
          $("#myModal").modal("hide");
          if (res["status"] == 200) {
            this.service.hideSpinner();
            this.service.showSuccessMessage(res["message"]);
            this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
            this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
            this.getbaseCoinBalance(this.coinDetail.baseCoin, "base");
            this.getbaseCoinBalance(this.coinDetail.execCoin, "exec");
            this.cancelAllOrderTrue = false
          } else {
            this.service.showErrorMessage(res["message"]);
            this.cancelAllOrderTrue = false
          }
        },
        (err) => {
          this.service.hideSpinner();
          $("#myModal").modal("hide");
          this.cancelAllOrderTrue = false
        }
      );
  }

  // GENERATING TRADING CHART FUNCTIONALITY
  drawTradingChart(coinDetail, resolution, id?) {
    if (window.location.pathname != '/buy-crypto') {
      return
    }
    this.refresh = false;
    let syb = (coinDetail.CoinPair).replaceAll('/', '')
    
    if (this.modeType == "day") {
      new TradingView.widget({

        fullscreen: true,
        "width": "100%",
        "autosize": true,
        "hide_side_toolbar": false,
        // "withdateranges": false,
        "withdateranges": true,
        // symbol: coinDetail.CoinPair,
        "symbol": syb,
        interval: "D",
        "timezone": "Asia/Kolkata",
        container_id: id || "tradingview_Ohl",
        // datafeed: new Datafeeds.UDFCompatibleDatafeed(
        //   this.service.chartUrl,
        //   10000
        // ),
        // "allow_symbol_change": true,
        "show_popup_button": true,
        "popup_width": "5000",
        "popup_height": "5000",
        // "no_referral_id": true,
        "enable_publishing": false,
        // "allow_symbol_change": true,
        locale: "en",
        "theme": "light",
        "studies": [
          "Volume@tv-basicstudies"
        ],
        // library_path: "assets/graph-library/charting_library/",
        drawings_access: {
          type: "black",
          tools: [{ name: "Regression Trend" }],
        },

        // disabled_features: ["use_localstorage_for_settings"],
      });
    } else {
      new TradingView.widget({
        fullscreen: true,
        "width": "100%",
        "autosize": true,
        "hide_side_toolbar": false,
        // "withdateranges": false,
        "withdateranges": true,
        "symbol": syb,
        // symbol: coinDetail.CoinPair,
        interval: resolution,
        "timezone": "Asia/Kolkata",
        container_id: id || "tradingview_Ohl",
        "show_popup_button": true,
        "popup_width": "5000",
        "popup_height": "5000",
        // "no_referral_id": true,
        locale: "en",
        "theme": "dark",
        "enable_publishing": false,
        // "allow_symbol_change": true,
        "studies": [
          "Volume@tv-basicstudies"
        ],
        // library_path: "assets/graph-library/charting_library/",
        drawings_access: {
          type: "black",
          tools: [{ name: "Regression Trend" }],
        },
        // disabled_features: ["use_localstorage_for_settings"]
      });
    }
    // }, 500);
    // this.callOHLCFunc()
  }
  /** Function to draw depth chart */


  // Get Balance Of Base Coin Functionality
  getbaseCoinBalance(baseCoin, type) {
    this.service.showSpinner();
    this.service
      .getCandyPixelForms("wallet/wallet/get-balance?coinName=" + baseCoin)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            if (type == "base") {
              this.baseCoinBalance = res["data"].walletBalance;
            } else {
              this.execCoinBalance = res["data"].walletBalance;
            }
          } else {
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }

  ngOnDestroy() {
    $("body").removeClass("night-theme");
    if (this.chart) {
      // AmCharts.destroyChart(this.chart);
    }
    clearInterval(this.myInterval);
    this.manageSubscribeAndUnsubscribeFunc(2);

    localStorage.removeItem("coinDetail");
    clearInterval(this.mysktInterval);

    this.subscribefunc(2);
    if (localStorage.getItem('statusEnableDisable') == 'ENABLE') {
      this.order_service_binance.dest()
    }
    if(this.binnace_livePrice_data){

      this.binnace_livePrice_data.desot()
    }
  }

  // Clear data From sellForm // suraj
  clearFilterOfSell() {
    this.sellPercentage = 0;
    this.marketSellForm.reset()
    this.limitSellForm.reset()
    this.sellForm.reset()
  }

  // Clear data From buyForm  // suraj
  clearFilterOfBuy() {
    this.marketBuyForm.reset()
    this.limitBuyForm.reset()
    this.buyForm.reset()
    this.buyPercentage = 0;
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }

  /** Function to restrict length after dot */
  restrictLength(type) {
    switch (type) {
      case "bsl":
        if (this.buyForm.value.stopLimit.includes(".")) {
          if (!this.regexForEightChar.test(this.buyForm.value.stopLimit)) {
            let tempVal = this.buyForm.value.stopLimit.split(".");
            this.buyForm.patchValue({
              stopLimit: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "bmp":
        if (this.marketBuyForm.value.marketBuyPrice.includes(".")) {
          if (
            !this.regexForEightChar.test(
              this.marketBuyForm.value.marketBuyPrice
            )
          ) {
            let tempVal = this.marketBuyForm.value.marketBuyPrice.split(".");
            this.marketBuyForm.patchValue({
              marketBuyPrice: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "smb":
        if (this.buyForm.value.price.includes(".")) {
          if (!this.regexForEightChar.test(this.buyForm.value.price)) {
            let tempVal = this.buyForm.value.price.split(".");
            this.buyForm.patchValue({
              price: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "lmp":
        if (this.limitBuyForm.value.limitBuyPrice.includes(".")) {
          if (
            !this.regexForEightChar.test(this.limitBuyForm.value.limitBuyPrice)
          ) {
            let tempVal = this.limitBuyForm.value.limitBuyPrice.split(".");
            this.limitBuyForm.patchValue({
              limitBuyPrice: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "bsq":
        if (this.marketBuyForm.value.marketBuyquantity.includes(".")) {
          if (
            !this.regexForEightChar.test(
              this.marketBuyForm.value.marketBuyquantity
            )
          ) {
            let tempVal = this.marketBuyForm.value.marketBuyquantity.split(".");
            this.marketBuyForm.patchValue({
              marketBuyquantity: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "lsb":
        if (this.limitBuyForm.value.limitBuyQuantity.includes(".")) {
          if (
            !this.regexForEightChar.test(
              this.limitBuyForm.value.limitBuyQuantity
            )
          ) {
            let tempVal = this.limitBuyForm.value.limitBuyQuantity.split(".");
            this.limitBuyForm.patchValue({
              limitBuyQuantity: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "ssb":
        if (this.buyForm.value.quantity.includes(".")) {
          if (!this.regexForEightChar.test(this.buyForm.value.quantity)) {
            let tempVal = this.buyForm.value.quantity.split(".");
            this.buyForm.patchValue({
              quantity: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "ssl":
        if (this.sellForm.value.stopLimit.includes(".")) {
          if (!this.regexForEightChar.test(this.sellForm.value.stopLimit)) {
            let tempVal = this.sellForm.value.stopLimit.split(".");
            this.sellForm.patchValue({
              stopLimit: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "spm":
        if (this.sellForm.value.price.includes(".")) {
          if (!this.regexForEightChar.test(this.sellForm.value.price)) {
            let tempVal = this.sellForm.value.price.split(".");
            this.sellForm.patchValue({
              price: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "ssq":
        if (this.sellForm.value.quantity.includes(".")) {
          if (!this.regexForEightChar.test(this.sellForm.value.quantity)) {
            let tempVal = this.sellForm.value.quantity.split(".");
            this.sellForm.patchValue({
              quantity: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "lsq":
        if (this.limitSellForm.value.limitSellQuantity.includes(".")) {
          if (
            !this.regexForEightChar.test(
              this.limitSellForm.value.limitSellQuantity
            )
          ) {
            let tempVal = this.limitSellForm.value.limitSellQuantity.split(".");
            this.limitSellForm.patchValue({
              limitSellQuantity: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "msq":
        if (this.marketSellForm.value.marketSellquantity.includes(".")) {
          if (
            !this.regexForEightChar.test(
              this.marketSellForm.value.marketSellquantity
            )
          ) {
            let tempVal =
              this.marketSellForm.value.marketSellquantity.split(".");
            this.marketSellForm.patchValue({
              marketSellquantity: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
      case "lpm":
        if (this.limitSellForm.value.limitSellPrice.includes(".")) {
          if (
            !this.regexForEightChar.test(
              this.limitSellForm.value.limitSellPrice
            )
          ) {
            let tempVal = this.limitSellForm.value.limitSellPrice.split(".");
            this.limitSellForm.patchValue({
              limitSellPrice: tempVal[0] + "." + tempVal[1].slice(0, 8),
            });
          }
        }
        break;
    }
  }

  showallcoindata() {
    var item = [];
    this.coinpair.map((e) => (e.marketValue = []));
    this.coinpair.forEach((ele, ind) => {
      if (ele.baseCoin == this.baseCoin && ind.execCoin == this.execCoin) {
        ele.execCoin.map((e) => {
          return this.service
            .getCandyPixelForms(
              "order-service-" +
              e.toLowerCase() +
              "_" +
              this.baseCoin.toLowerCase() +
              "/market-data?symbol=" +
              e +
              "_" +
              this.baseCoin
            )
            .subscribe((res) => {
              item.push(res["data"].lastPrice);
            });
        });
      }
    });
    this.coinpair.map((e) => {
      if (e.baseCoin == this.baseCoin) {
        e.marketValue = item;
      }
    });
  }

  sortData1(arr) {
    arr.sort((a, b) => b.time - a.time);
    return arr;
  }

  sortDatasell(arr) {
    arr.sort((a, b) => b.price - a.price);
    return arr;
  }

  sortDatabuy(arr) {
    arr.sort((a, b) => b.price - a.price);
    return arr;
  }

  orderNav(val) {
    this.orderVal = val;
  }

  // order switch between sell, buy, both
  orderBookTabShow: any = "Both";
  // Order Status Functionality
  orderBookTab(status) {
    switch (status) {
      case "Both":
        this.orderBookTabShow = "Both";
        break;
      case "Buyers":
        this.orderBookTabShow = "Buyers";
        break;
      case "Sellers":
        this.orderBookTabShow = "Sellers";
        break;
    }
   
  }

  // buy sell trade
  buySellTradeTabShow: any = "Buy";
  buySellTradeTab(status) {
    switch (status) {
      case "Buy":
        this.buySellTradeTabShow = "Buy";
        break;
      case "Sell":
        this.buySellTradeTabShow = "Sell";
        break;
    }
  }
  minValueArray: any = []
  allBaseCoins: any
  getAllCoinPairFuncs() {
    this.service.showSpinner();
    this.arr = [];
    this.service.getCandyPixelForms("wallet/coin/get-symbol-list").subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.allBaseCoins = res.data
          this.baseCoin = this.coinDetail.base;
          this.execCoin = this.coinDetail.exec;
          var ind = res.data.findIndex((x) => x.baseCoin == this.basecoins);
          if (ind > -1) {
            res.data[ind].execCoin.forEach((element) => {
              this.arr.push({
                base: this.basecoins,
                executable: element,
                lastPrice: "",
                hourChange: "",
                hourhigh: "",
                hourLow: "",
                hourVol: "",
                minTradeValue: this.favData
              });
            });
          }
          // this.subscribefunc(1);
          setTimeout(() => {
            this.subscribefunc(1);
          }, 1000);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err['status'] == 503) {
          this.route.navigate(['/error'])
          this.service.hideSpinner();

        }
      }
    );
  }

  subscribefunc(mode) {
    if (order.orderStatus == 'ENABLE') {
      return
    }
    switch (mode) {
      case 1:
        this.arr.forEach((ele) => {
          let data = {
            messageType: "SUBSCRIBE_TICKER",
            params: {
              symbol: ele.executable + "_" + ele.base,
            },
          };
          if (this.order_service.wsExchange.readyState) {
            this.order_service.wsExchange.send(JSON.stringify(data));
          }
        });
        break;
      case 2:
        this.arr.forEach((ele) => {
          let data = {
            messageType: "UNSUBSCRIBE_TICKER",
            params: {
              symbol: ele.executable + "_" + ele.base,
            },
          };
          if (this.order_service.wsExchange.readyState) {
            this.order_service.wsExchange.send(JSON.stringify(data));
          }
        });
    }
  }

  /**
   * Slider
   */
  liquidity: any = []
  uptoTwo(e) {
    return Number(e).toFixed(2)
  }

  // to get coin list

  changeSliderValue(e) {
  }
  ask: any = []
  bids: any = []
  askData: any = []
  bidsData: any = []
  getGraphData() {
    let url = `account/cmc-data-ohlc?symbol=BTC%2CETH`

    this.service.getCandyPixelForms(url).subscribe((res: any) => {

      let grphData = (JSON.parse(res.data))
      grphData.data.BTC[0].quotes.forEach((item) => {
        let arr1 = []
        arr1 = [item.quote.USD.high]
        let arr2 = []
        arr2 = [item.quote.USD.low]
        this.ask.push(arr1)
        this.bids.push(arr2)
      });
      grphData.data.ETH[0].quotes.forEach((item, index) => {


        let arr1 = this.ask[index]
        let arr2 = this.bids[index]
        arr1.push(item.quote.USD.high)
        arr2.push(item.quote.USD.low)

        this.askData.push(arr1)
        this.bidsData.push(arr2)


      });
      this.drawDepthChart()

    })

  }
  callOHLCFunc() {
    this.chart = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      title: {
        text: "CRYPTOBIZ"
      },
      axisX: {
        valueFormatString: "MMM"
      },
      axisY: {
        title: "",
        includeZero: false,
        prefix: "",
        lineThickness: 0
      },
      axisY2: {
        title: "Volume",
        labelFormatter: addSymbols
      },
      toolTip: {
        shared: true
      },
      legend: {
        cursor: "pointer",
        itemclick: toggleDataSeries
      },
      data: [{
        type: "ohlc",
        xValueFormatString: "MMMM 2020",
        name: "",
        showInLegend: true,
        yValueFormatString: "$###0.00",
        toolTipContent: "<b>{x}</b><br><span style=\"color:#4F81BC\">{name}</span>: <br>Open: {y[0]}<br>High: {y[1]}<br>Low: {y[2]}<br>Close: {y[3]}<br><b>Adj. Close</b>: {y[4]}",
        dataPoints: this.arr
      }]
    });

    this.chart.render();

    function addSymbols(e) {
      var suffixes = ["", "K", "M", "B"];
      var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

      if (order > suffixes.length - 1)
        order = suffixes.length - 1;

      var suffix = suffixes[order];
      return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
    }

    function toggleDataSeries(e) {
      if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      e.chart.render();
    }
  }
  drawDepthChart() {

    let currVal = this.baseCoin;
    let exeVal = this.execCoin;
    AmCharts.makeChart("depth_chart", {
      type: "serial",
      theme: "light",
      dataLoader: {
        url:
          this.service.chartUrl +
          "/depth-chart?currency=" +
          currVal +
          "&exchangeCurrency=" +
          exeVal,

        format: "json",
        reload: 30,
        postProcess: function (data) {
          var response = JSON.stringify(data);
          var parsedData = JSON.parse(response);
          var asks = parsedData.data.asks;
          var bids = parsedData.data.bids;
          // Function to process (sort and calculate cummulative volume)
          function processData(list, type, desc) {
            // Convert to data points
            for (var i = 0; i < list.length; i++) {

              list[i] = {
                value: Number(list[i][0]),
                volume: Number(list[i][1]),
              };
            }
            // Sort list just in case
            list.sort(function (a, b) {
              if (a.value > b.value) {
                return 1;
              } else if (a.value < b.value) {
                return -1;
              } else {
                return 0;
              }
            });
            // Calculate cummulative volume
            if (desc) {
              for (var i = list.length - 1; i >= 0; i--) {
                if (i < list.length - 1) {
                  list[i].totalvolume =
                    list[i + 1].totalvolume + list[i].volume;
                } else {
                  list[i].totalvolume = list[i].volume;
                }
                var dp = {};
                dp["value"] = list[i].value;
                dp[type + "volume"] = list[i].volume;
                dp[type + "totalvolume"] = list[i].totalvolume;
                res.unshift(dp);
              }
            } else {
              for (var i = 0; i < list.length; i++) {
                if (i > 0) {
                  list[i].totalvolume =
                    list[i - 1].totalvolume + list[i].volume;
                } else {
                  list[i].totalvolume = list[i].volume;
                }
                var dp = {};
                dp["value"] = list[i].value;
                dp[type + "volume"] = list[i].volume;
                dp[type + "totalvolume"] = list[i].totalvolume;
                res.push(dp);
              }
            }
          }
          var res = [];
          processData(bids, "bids", true);
          processData(asks, "asks", false);
          return res;
        },
      },
      graphs: [
        {
          id: "bids",
          fillAlphas: 0.1,
          lineAlpha: 1,
          lineThickness: 2,
          lineColor: "#0f0",
          stroke: "#000",
          fill: "#000",
          type: "step",
          valueField: "bidstotalvolume",
          balloonFunction: balloon,
        },
        {
          id: "asks",
          fillAlphas: 0.1,
          lineAlpha: 1,
          lineThickness: 2,
          lineColor: "#f00",
          stroke: "#000",
          fill: "#000",
          type: "step",
          valueField: "askstotalvolume",
          balloonFunction: balloon,
        },
        {
          lineAlpha: 0,
          fillAlphas: 0.2,
          lineColor: "#000",
          stroke: "#000",
          fill: "#000",
          type: "column",
          clustered: false,
          valueField: "bidsvolume",
          showBalloon: false,
        },
        {
          lineAlpha: 0,
          fillAlphas: 0.2,
          lineColor: "#000",
          stroke: "#000",
          fill: "#000",
          type: "column",
          clustered: false,
          valueField: "asksvolume",
          showBalloon: false
        },
      ],
      categoryField: "value",
      chartCursor: {},
      balloon: {
        textAlign: "left",
      },
      categoryAxis: {
        minHorizontalGap: 100,
        startOnAxis: true,
        showFirstLabel: false,
        showLastLabel: false,
      },
      export: {
        enabled: true,
      },
      expend: {
        enabled: true
      }
    });

    function balloon(item, graph) {
      var txt;
      if (graph.id == "asks") {
        txt =
          "Ask: <strong>" +
          formatNumber(item.dataContext.value, graph.chart, 2) +
          "</strong><br />" +
          "Total volume: <strong>" +
          formatNumber(item.dataContext.askstotalvolume, graph.chart, 2) +
          "</strong><br />" +
          "Volume: <strong>" +
          formatNumber(item.dataContext.asksvolume, graph.chart, 2) +
          "</strong>";
      } else {
        txt =
          "Bid: <strong>" +
          formatNumber(item.dataContext.value, graph.chart, 2) +
          "</strong><br />" +
          "Total volume: <strong>" +
          formatNumber(item.dataContext.bidstotalvolume, graph.chart, 2) +
          "</strong><br />" +
          "Volume: <strong>" +
          formatNumber(item.dataContext.bidsvolume, graph.chart, 2) +
          "</strong>";
      }
      return txt;
    }
    function formatNumber(val, chart, precision) {
      return AmCharts.formatNumber(val, {
        precision: precision ? precision : chart.precision,
        decimalSeparator: chart.decimalSeparator,
        thousandsSeparator: chart.thousandsSeparator,
      });
    }
  }

  getBuyOrSellType(type) {
    this.buyOrSellType = type;
    this.getCoinListFunc(this.coinDetail.baseCoin, this.coinDetail.execCoin)
  }

  selectedBaseCurrency: any = 'BTC'
  searchBaseCoin: any = '';
  getSelectedBaseCurrency(baseCurrency) {
    this.searchBaseCoin = ''
    this.selectedBaseCurrency = baseCurrency
  }


  orderBookPrice(orderPriceDecimalLabel) {
    this.orderPriceDecimalLabel = orderPriceDecimalLabel
  }


  favouriteCoin(exec, baseCoin) {
    let url = `wallet/coin/set-coinPair-favourite-unfavourite-true?baseCoin=${exec}&executableCoin=${baseCoin}`
    // this.service.showSpinner();
    this.service
      .postApi(url, {})
      .subscribe(
        (res: any) => {
          if ((res.status = 200)) {
            this.getAllCoinPairFunc();
            this.getfavuriteList()
            // this.editData=res.data[0];
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err['status'] == 503) {
            this.route.navigate(['/error'])
            this.service.hideSpinner();

          }
          else {
            this.service.hideSpinner();
            // this.service.showErrorMessage("Please First Login Your Account");
          }
        }
      );
  }
  favData: any = []
  allBaseCoins2: any
  getfavuriteList() {
    // let url =`wallet/coin/get-coinPair-list-by-favourite`
    let url = `wallet/coin/get-coinPair-list`
    this.service
      .postApi(url, {})
      .subscribe(
        (res: any) => {
          if ((res.status = 200)) {
            this.favData = res['data']
            this.allBaseCoins2 = res.data
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err['status'] == 503) {
            this.route.navigate(['/error'])
            this.service.hideSpinner();

          }
          else {
            this.service.hideSpinner();
            // this.service.showErrorMessage("Please First Login Your Account");
          }
        }
      );
  }
  filterCoin(e) {
    let coinName = String(e.target.value).toLowerCase();
    this.coinpair = this.coinpair.filter((element) => {
      let cName = String(element.execCoin).toLowerCase();
      return cName.includes(coinName);
    });
    let studentObj = this.coinpair.find(t => t.execCoin);
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
  getCoinPairS(e) {

    let arr = String(e).split('/')
    arr.reverse()
    return arr.toString()
  }

  _baseCurrency = "USDT"
  getBaseCurrency(currency, coinTab) {
    this._baseCurrency = currency;
    this.activeCoin = coinTab
    this.getMinimuvalue()
  }
  isFavoriteCoin(baseCoin, exec): boolean {
    let temp = this.favData.filter((res) => {
      return res.baseCoin == baseCoin && res.executableCoin == exec
    })
    let filtered = temp[0]


    return filtered['isFavourite'] ? true : false


  }
  getCurrentPrice(coinName) {
    let filterArray = []
    filterArray = this.coinListData.filter((res) => {
      return res.coinShortName == coinName
    })
    return filterArray[0] ? filterArray[0] : 0

  }
  buyOrderPrice: any
  sendBuyPrice(item) {
    this.buyOrderPrice = item
    this.marketBuyForm.patchValue({
      marketBuyPrice: this.buyOrderPrice
    })
  }
  sellOrderPrice: any
  sendSellPrice(item) {
    this.sellOrderPrice = item
    this.marketSellForm.patchValue({
      marketSellPrice: this.sellOrderPrice
    })
  }
  fullScreenMode: boolean = false
  viewFull() {
    this.fullScreenMode = true
    // $(".amclass-trade").attr("id", "tradingview_Ohl-1");
    setTimeout(() => {

      this.drawTradingChart(this.coinDetail, "D", 'tradingview_Ohl-1');
    }, 100);
    this.escape()


  }
  exitFullScreen() {
    this.fullScreenMode = false
  }
  escape() {
    let me = this
    document.onkeydown = function (evt: any) {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        me.exitFullScreen()
      }
    }
  }
  naviagetDetailPage(action) {
    this.route.navigate([`/market-info-details/${(this.coinDetail.execCoin).toUpperCase()}`])
  }
  minTradeValue: any
  getMinimuvalue() {
    let url = `wallet/coin/get-coin-pair-detail?baseCoin=${this.baseCoin}&exCoin=${this.execCoin}`
    this.service.getCandyPixelForms(url).subscribe(
      (res: any) => {
        if ((res.status = 200)) {
          this.minTradeValue = res['data']['minimumValue']
        }
      },
      (err) => {
        if (err['status'] == 503) {
          this.route.navigate(['/error'])

        }
        else {
          this.service.hideSpinner();
        }
      }
    );
  }

  liquidityData: any = []
  liquidityCount: any
  currentPageOrder: any = 1
  limitOrder: any = 5
  liquiditys() {
    let url = `order/liquidity-list?instrument=${(this.coinDetail.coinPair).toUpperCase()}&page=${this.currentPageOrder - 1}&pageSize=${this.limitOrder}&orderStatus=NEW`
    this.service.getCandyPixelForms(url).subscribe(
      (res: any) => {
        if (res["status"] == 200) {
          this.liquidityData = res.data.List
          this.liquidityCount = res.data.count
        } else {
          // this.service.showErrorMessage(res.message);
          this.liquidityData = []
        }
      },
      (err) => {
        this.liquidityData = []
        this.service.hideSpinner();
        // this.service.showErrorMessage(err.message);
      }
    );
  }
  LiquidityPagination(page) {
    this.currentPageOrder = page;
    this.liquidity();
  }
  getPercentage() {
    return Math.floor(Math.random() * 100) + 1
  }
  spinner = false
  refreshList(){
    this.spinner = true
    this.ngOnInit()
    setTimeout(() => {
      this.spinner = false
    }, 3000);
  }
}



const order = new OrderBook()