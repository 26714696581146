import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CoinlistWebService } from "src/app/coinlist-web.service";
import { ServiceService } from "src/app/service.service";
declare var $;
@Component({
  selector: "app-swap-crypto",
  templateUrl: "./swap-crypto.component.html",
  styleUrls: ["./swap-crypto.component.css"],
})
export class SwapCryptoComponent implements OnInit {
  showDetail: any = "ALLCRYPTO";
  searchStatus: boolean = false;
  showMarketInfo = false
  coinpair: any = [];
  coinDetail: any = {};
  execCoin: any = "BTC";
  baseCoin: any = "USD";
  marketdata: any;
  arr: any = [];
  basecoins = "BTC";
  executables: any;
  tab = "BTC Market";
  baseCoinFun: string;
  baseCoinVal: string;
  myInterval: any;
  pageNumberBuy: any = 1;
  pageNumberSell: any = 1;
  pageSize: any = 5;
  sellTotal: any;
  buyTotal: any;
  // slider
  coinList: any = [];
  filteredCoinList: any = [];
  currentT: any = "ALLCRYPTO";
  fala: any
  activeTab: any = ""

  constructor(public service: ServiceService, public route: Router, public coinListService: CoinlistWebService) {
    window.scrollTo(0, 0);
  }

  extraCoin = {
    baseCoin: "BNB"
  }

  cmcData: any;
  selectedCurrency: any = 'USD'

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getAllCoinPairFunc();
    this.getCoinList();
    this.service.selectedCurrencySub.subscribe(res => {
      this.selectedCurrency = res
    })

  }

  bankDetails(showSection) {

    this.currentT = showSection
    this.showDetail = showSection;
    if (showSection != 'COMPANY') {
      if (showSection == 'ALLCRYPTO') {
        this.coinList = this.allCoinList
      }
      else if (showSection == 'INCREASE') {
        this.coinList = this.increaseCoinList
      }
      else if (showSection == 'DECREASE') {
        this.coinList = this.decreseCoinList
      }
      else if (showSection == 'USER') {
        this.coinList = this.lastIndexArray
      }

    }



  }
  baseCurrency: any = []
  baseCurrency111: any = []
  fullCoin: any = []
  exeCurrency: any = []
  getAllCoinPairFunc() {
    this.arr = [];
    this.service.getCandyPixelForms("wallet/coin/get-symbol-list").subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.baseCurrency = res['data'];
          this.baseCoin = this.coinDetail.base;
          this.execCoin = this.coinDetail.exec;
          var ind = res.data.findIndex((x) => x.baseCoin == this.basecoins);
          if (ind > -1) {
            res.data[ind].execCoin.forEach((element) => {
              this.arr.push({
                base: this.basecoins,
                executable: element,
                lastPrice: "",
                hourChange: "",
                hourhigh: "",
                hourLow: "",
                hourVol: "",
              });
            });
          }
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }


  trendList: any = [];
  lastIndexArray: any = []
  lastThreeArray: any = []
  filterCoin(e) {
    let coinName = String(e.target.value).toLowerCase();
    this.coinList = [...this.allCoinList].filter((element) => {
      let cName = String(element.coinShortName).toLowerCase();
      return cName.includes(coinName);
    });
  }
  favListArray: any = [];
  myfavListArray: any = []
  trendingList: any = []
  increaseList: any = []

  // ------------
  allCoinList: any = []
  increaseCoinList: any = []
  decreseCoinList: any = []

  getCoinList() {
    // this.coinListService.dataGet().subscribe((res: any) => {
    //   if (res.messageType == 'COINLIST_UPDATE') {
    this.service.getCandyPixelForms("wallet/coin/get-coin-list").subscribe((res: any) => {
      if (res["status"] == 200) {
        this.myfavListArray = []
        for (let ele of res["data"]) {
          if (ele.isFavourite) {
            this.myfavListArray.push(ele.coinShortName)
          }
        }

        this.coinList = res["data"];
        this.filteredCoinList = this.coinList;

        for (let i = 0; i < this.coinList.length; i++) {
          if (this.coinList[i].percent_change_24h_usd > 0) {
            this.increaseList.push(this.coinList[i])
            if (this.increaseList.length >= 3) {
              break
            }
          }

        }
        this.lastThreeArray = this.coinList.slice(-3);
        this.lastIndexArray = this.coinList.slice(-3);



        this.allCoinList = [...res.data]
        this.increaseCoinList = [...res.data].sort((a, b) => {
          return b.percent_change_24h_usd - a.percent_change_24h_usd
        })
        this.decreseCoinList = [...res.data].sort((a, b) => {
          return a.percent_change_24h_usd - b.percent_change_24h_usd
        })
      }
    });
  }

  buyPaginationEvent(page) {
    this.pageNumberSell = page;
    if (this.searchStatus == true) {
      this.getCoinList();
    } else {
    }
  }
  currUrl = "xindia";

  scrol(e) {
    this.currUrl = e;
    var ele = document.getElementById(e);
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
  }

  favouriteCoin(coinId, status) {
    this.service.showSpinner();
    this.service
      .putApi("wallet/coin/set-coin-fav/unfav?id=" + coinId + "&status=" + status, {})
      .subscribe(
        (res: any) => {
          if ((res.status = 200)) {
            // this.getCoinList();
            this.filteredCoinList.map((ele) => {
              if (ele.coinId == coinId) {
                ele.favourite = status
              }
            })
            // this.editData=res.data[0];
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            this.service.showErrorMessage("Unauthorized Access");
          } else {
            this.service.showErrorMessage("Please First Login Your Account");
          }
        }
      );
  }
  goToInfo(coinName: string) {
    let coin = coinName.trim()
    coin = coin.replace(" ", "-")
    coin = coin.toLowerCase()
    let a = document.createElement('a')
    a.href = `https://coinmarketcap.com/currencies/${coin}`
    a.target = "_blank"
    a.click()
  }
  removeFavouriteCoin(coinShortName) {
    this.service.showSpinner();
    this.service
      .postApi("wallet/coin/set-coin-unfavourite?coinName=" + coinShortName, {})
      .subscribe(
        (res: any) => {
          if ((res.status = 200)) {
            this.getCoinList();
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            this.service.showErrorMessage("Unauthorized Access");
          } else {
            this.service.showErrorMessage("Something Went Wrong");
          }
        }
      );
  }
  removeFavouriteCoinList(coinShortName) {
    this.service.showSpinner();
    this.service
      .postApi("wallet/coin/set-coin-unfavourite?coinName=" + coinShortName, {})
      .subscribe(
        (res: any) => {
          if ((res.status = 200)) {
            this.getCoinList();
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            this.service.showErrorMessage("Unauthorized Access");
          } else {
            this.service.showErrorMessage("Something Went Wrong");
          }
        }
      );
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
  subTab = "All"
  categoryList: any = []
  getCategoryList(category) {
    this.subTab = category
    if (category == 'All') {
      return
    }
    let url = `wallet/coin/get-coin-by-category?category=${category}`
    this.service
      .getCandyPixelForms(url)
      .subscribe(
        (res: any) => {
          if ((res.status = 200)) {
            this.categoryList = res.data
            this.service.hideSpinner();
          } else {

          }

        },
      );
  }
  getPriceData(dataKey, coinShort, length) {
    let temp = this.filteredCoinList.filter((res) => {
      return res.coinShortName == coinShort
    })
    return this.toFixed(temp[0][dataKey], length)
  }

  _baseCurrency = "USDT"
  getBaseCurrency(currency, coinTab?) {
    this._baseCurrency = currency
    this.activeTab = coinTab

  }
  navigateToTradePair(baseCoin, execCoin) {
    this.route.navigate(['/buy-crypto'], { queryParams: { baseCoin, execCoin } })
  }
  getCurrencyValue: any
  getPriceDataConvertWithCurrencyRate(dataKey, coinShort, length, currency: string) {
    let temp = this.filteredCoinList.filter((res) => {
      return res.coinShortName == coinShort
    })
    let getCurrencyValue = localStorage.getItem('currencyPrice')
    this.getCurrencyValue = JSON.parse(getCurrencyValue)
    let currencyRate = this.getCurrencyValue[currency]
    if (currencyRate) {
      return this.toFixed((Number(temp[0][dataKey]) * currencyRate), length)
    } else {
      return this.toFixed(temp[0][dataKey], length)
    }
  }
  formatNumber(input) {
    // Extract numeric part from the input string
   
    // Check if the extracted number is valid
    if (!input) return "0.00";
  
    let numberPart = parseFloat(String(input).replace(/[^0-9.]/g, ''));
    let textPart = String(input).replace(/[0-9.]/g, '');

    if (!numberPart || isNaN(numberPart)) return "0.00";

    // Format the numeric part
    let formattedNumber;
    if (numberPart >= 1e12) {
      formattedNumber = (numberPart / 1e12).toFixed(3) + 'T';
    } else if (numberPart >= 1e9) {
      formattedNumber = (numberPart / 1e9).toFixed(3) + 'B';
    } else if (numberPart >= 1e6) {
      formattedNumber = (numberPart / 1e6).toFixed(3) + 'M';
    }
    else if (numberPart >= 1e3) {
      formattedNumber = (numberPart / 1e3).toFixed(2) + 'K';
    }

    else {
      formattedNumber = numberPart.toFixed(3);
    }

    // Combine the formatted number with the text part
    if (textPart) {
      textPart = textPart.replace(/,/g, '')
      return textPart + formattedNumber
    }
    return formattedNumber;
  }
  isIncrease  = true
  sortData(key: string) {
    this.coinList = this.isIncrease
      ? this.coinList.sort((a, b) => {
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
          return 0;
        })
      : this.coinList.sort((a, b) => {
          if (a[key] < b[key]) return 1;
          if (a[key] > b[key]) return -1;
          return 0;
        });
    
    this.isIncrease = !this.isIncrease;
  }
 
}
