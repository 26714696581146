import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-add-new-bank',
  templateUrl: './add-new-bank.component.html',
  styleUrls: ['./add-new-bank.component.css']
})
export class AddNewBankComponent implements OnInit {
  addAccountForm: FormGroup
  isLoading:boolean =false;
  constructor(private router: Router, public service: ServiceService, public activatedRoute: ActivatedRoute) {
    this.formValidation()
   }

  ngOnInit() {
  }
  formValidation() {
    this.addAccountForm = new FormGroup({
      'accountHolderName': new FormControl('', [Validators.required]),
      'accountNumber': new FormControl('', [Validators.required, Validators.pattern(/^(?!0+$)[a-zA-Z0-9-]{5,24}$/)]),
      'bankName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/)]),
      'ifsc': new FormControl('', [Validators.required,Validators.pattern(/^(?!0+$)[a-zA-Z0-9-]{3,10}$/)]),
      'reEnterAccountNumber': new FormControl('', [Validators.required]),

    })
  }
  
  isCheckValid(){
    return this.addAccountForm.value.accountNumber!= this.addAccountForm.value.reEnterAccountNumber
  }
   submit() {
    this.isLoading =true
    let data = {
      'accountNo': this.addAccountForm.value.accountNumber,
      'accountHolderName': this.addAccountForm.value.accountHolderName,
      "ifsc": this.addAccountForm.value.ifsc,
      'bankName': this.addAccountForm.value.bankName,
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('wallet/add-user-bank-account?role=USER', data).subscribe(res => {
      this.isLoading =false
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.service.isUpdatedItem.next(true)
        this.service.showSuccessMessage('Successfully submitted, we will get back to you shortly!')
        this.addAccountForm.reset();
      }else{
        this.service.showErrorMessage(res['message'])

      }
    }, err => {
      this.isLoading =false
      this.service.hideSpinner();
    })
  }
  disableReadonly(event: FocusEvent): void {
    const target = event.target as HTMLInputElement;
    target.readOnly = false;
  }
}
